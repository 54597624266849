import './edit-customer-details.component.scss';

import React, { Dispatch, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal, notification } from 'antd';
import SurveyResponse from '../survey-response/survey-response.component';
import { CustomerService } from '../../../../services/customer.service';
import { ALL_USERS_COMPLETED, CUSTOMER_LIST, CUSTOMER_RAWID_EMPTY, CUSTOMER_WITH_RAWID, DATA_FETCHING_ERROR, DATA_LOST_INFORMATION, DATA_SUCCESS, NO_PREVIOUS_DATA, NO_USER_WITH_RAWID, PREVIOUS_RECORD_FETCH, TATVAM_RATING_EMPTY } from '../../../../constants/messages';
import { CUSTOMER } from '../../../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../../../store/actions/loader.actions';
import { IAccount, IStateType } from '../../../../store/models';
import moment from 'moment';
import { DateFormat } from '../../../../enum/date-format.enum';
import { CONFIRMATION, YES, NO, ALERT } from '../../../../constants/title';

const EditCustomerDeatils: React.FC<any> = (props: any) => {
    const navigate = useNavigate();
    const { confirm } = Modal;
    const dispatch: Dispatch<any> = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false as boolean);
    const [headerData, setHeaderData] = useState({
        remaining_records: 0,
        total_records: 0
    })
    const location = useLocation();
    const [serveyData, setServeyData] = useState({} as any);
    const customerService = new CustomerService();
    const account: IAccount = useSelector((state: IStateType) => state?.account);
    const [form] = Form.useForm();
    const [gotoRawID, setGotoRawID] = useState("" as string);
    const [rawIdData, setRawIdData] = useState(false as boolean);

    useEffect(() => {
        if (location?.state || location.state?.rowData?.customer_id) {
            getServeyDetails();
        }
    }, []);

    const getServeyDetails = () => {
        const loaderRef: string = 'Customer';
        dispatch(showLoader(loaderRef));
        customerService.getCustomerServeyData({
            customer_id: location.state?.rowData?.customer_id,
            source_name: location.state?.rowData?.source_name,
            process_date: location.state?.rowData?.process_date,
            process_status: location.state?.status
        })
            .then((response: any) => {
                dispatch(hideLoader(loaderRef));
                setServeyData(response?.data[0]);
                setHeaderData(
                    {
                        remaining_records: response?.remaining_records,
                        total_records: response?.total_records
                    }
                )
                setDataLoaded(true);
            })
            .catch((responseError) => {
                setHeaderData(
                    {
                        remaining_records: 0,
                        total_records: 0
                    }
                )
                dispatch(hideLoader(loaderRef));
                notification['error']({
                    message: CUSTOMER_LIST,
                    description: DATA_FETCHING_ERROR,
                });
            });
    };

    const getServeyDetailsWithRawID = () => {
        const loaderRef: string = 'Customer';
        dispatch(showLoader(loaderRef));
        if (gotoRawID.length === 0 || gotoRawID.trim() === "") {
            Modal.warning({
                title: "Alert",
                content: CUSTOMER_RAWID_EMPTY,
                onOk: () => { setGotoRawID("") }
            });
        }
        else {
            customerService.getCustomerDataByRawID(gotoRawID)
                .then((response: any) => {
                    if (Object.keys(response)?.length > 0) {
                        setGotoRawID("");
                        setServeyData(response?.data);
                        form.resetFields();
                        setRawIdData(true);
                        setHeaderData(
                            {
                                remaining_records: response?.remaining_records,
                                total_records: response?.total_records
                            }
                        );
                        notification['success']({
                            message: CUSTOMER_LIST,
                            description: CUSTOMER_WITH_RAWID,
                        });
                        setDataLoaded(true);
                    } else {
                        Modal.warning({
                            title: "Alert",
                            content: NO_USER_WITH_RAWID,
                            onOk: () => { setGotoRawID("") }
                        });
                    }
                    setDataLoaded(true);
                })
                .catch((responseError) => {
                    setHeaderData(
                        {
                            remaining_records: 0,
                            total_records: 0
                        }
                    )
                    setRawIdData(true);
                    dispatch(hideLoader(loaderRef));
                    notification['error']({
                        message: CUSTOMER_LIST,
                        description: DATA_FETCHING_ERROR,
                    });
                });
        }
    };

    const getPreviousServeyDetails = () => {
        const loaderRef: string = 'Customer';
        dispatch(showLoader(loaderRef));
        customerService.getPreviousServeyData({
            customer_id: location.state?.rowData?.customer_id,
            source_name: location.state?.rowData?.source_name,
            process_date: location.state?.rowData?.process_date,
            process_status: location.state?.status,
            rawid: serveyData?.rawid
        })
            .then((response: any) => {
                if (Object.keys(response)?.length > 0) {
                    setServeyData(response?.data);
                    form.resetFields();
                    setHeaderData(
                        {
                            remaining_records: response?.remaining_records,
                            total_records: response?.total_records
                        }
                    );
                    notification['success']({
                        message: CUSTOMER_LIST,
                        description: PREVIOUS_RECORD_FETCH,
                    });
                    setDataLoaded(true);
                } else {
                    Modal.warning({
                        title: ALERT,
                        content: NO_PREVIOUS_DATA,
                        onOk: () => { }
                    });
                }
                setDataLoaded(true);
            })
            .catch((responseError) => {
                dispatch(hideLoader(loaderRef));
                notification['error']({
                    message: CUSTOMER_LIST,
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const handelServeyResponse = (serveyResponse: any) => {
        let serveyPayload: any[] = [];
        serveyResponse.forEach((question: any) => {
            if (question?.process_status.toLowerCase() !== "Deleted".toLowerCase()) {
                question["process_status"] = (question?.previous_tatvam_rating === question?.tatvam_rating) ? 
                "Processed" : "Fixed";
            }
            if (question?.annotations?.length > 0) {
                serveyPayload.push(
                    {
                        _id: question?._id,
                        previous_tatvam_rating: question?.previous_tatvam_rating,
                        tatvam_rating: question?.tatvam_rating,
                        process_status: question?.process_status,
                        annotations: handelServeyResponse(question?.annotations),
                        notes: question?.notes,
                        interested: question?.interested
                    }
                )
            } else if (question?.tatvam_rating > 0 && question?.annotations?.length <= 0) {
                serveyPayload.push({
                    _id: question?._id,
                    previous_tatvam_rating: question?.previous_tatvam_rating,
                    tatvam_rating: question?.tatvam_rating,
                    process_status: question?.process_status,
                    notes: question?.notes,
                    interested: question?.interested
                })
            } else {
                serveyPayload.push(
                    {
                        _id: question?._id,
                        previous_tatvam_rating: question?.previous_tatvam_rating,
                        tatvam_rating: question?.tatvam_rating,
                        process_status: question?.process_status,
                        interested: question?.interested
                    }
                )
            }
        })
        return serveyPayload
    };

    const handelStatusConfirmation = () => {
        ////Remove the toaster mesage
        // if ((serveyData?.process_status.toLowerCase() !== serveyData?.previous_process_status.toLowerCase()) && (serveyData?.previous_process_status.toLowerCase() !== "Yet To Process".toLowerCase())) {
        //     Modal.confirm({
        //         title: CONFIRMATION,
        //         content: `This record is already marked as ${serveyData?.previous_process_status}. Do you want to change it as ${serveyData?.process_status}`,
        //         okText: YES,
        //         cancelText: NO,
        //         onOk: () => { handelUpdateStatuses() },
        //         onCancel: () => { handelUpdateCancelStatus() }
        //     })
        // } else {
        //     handelUpdateStatuses();
        // }
        handelUpdateStatuses();
    }

    const handelUpdateCancelStatus = () => {
        serveyData["process_status"] = serveyData?.previous_process_status;
        handelUpdateStatuses();
    }

    const handelUpdateStatuses = () => {
        let isValidTatvamRating = true;
        serveyData?.responses.forEach((question: any) => {
            if ((question.previous_tatvam_rating > 0) && (question.tatvam_rating === "" || question.tatvam_rating === undefined || question.tatvam_rating === null)) {
                Modal.warning({
                    title: ALERT,
                    content: TATVAM_RATING_EMPTY,
                    onOk: () => { }
                })
                isValidTatvamRating = false;
                return
            }
            if (question.annotations.length > 0) {
                question?.annotations?.forEach((annotations: any) => {
                    if ((annotations.previous_tatvam_rating > 0) && (annotations.tatvam_rating === "" || annotations.tatvam_rating === undefined || annotations.tatvam_rating === null)) {
                        Modal.warning({
                            title: ALERT,
                            content: TATVAM_RATING_EMPTY,
                            onOk: () => { }
                        })
                        isValidTatvamRating = false;
                        return
                    }
                })
            }
        })
        if (isValidTatvamRating) {
            const payloadData = {
                customer_id: location?.state?.rowData?.customer_id,
                source_name: location?.state?.rowData?.source_name,
                process_date: location?.state?.rowData?.process_date,
                process_status: location?.state?.status,
                raw_data: {
                    responses: handelServeyResponse(serveyData?.responses),
                    rawid: serveyData?.rawid,
                    verified_by: account?.user_name,
                    verified_time: new Date(),
                    updated_by: account?.user_name,
                    tatvam_rating: serveyData?.tatvam_rating,
                    process_status: serveyData?.process_status,
                    previous_tatvam_rating: serveyData?.previous_tatvam_rating,
                }
            }

            const loaderRef: string = 'Customer';
            dispatch(showLoader(loaderRef));
            customerService.updateCustomerServeyData(payloadData)
                .then((response: any) => {
                    if (response?.data?.length > 0) {
                        setServeyData(response?.data[0]);
                        form.resetFields();
                        setHeaderData(
                            {
                                remaining_records: response?.remaining_records,
                                total_records: response?.total_records
                            }
                        )
                        // notification['success']({
                        //     message: CUSTOMER_LIST,
                        //     description: DATA_SUCCESS,
                        // });
                    } else {
                        Modal.warning({
                            title: ALERT,
                            content: ALL_USERS_COMPLETED,
                            onOk: () => {
                                navigate(CUSTOMER);
                            }
                        });
                    }
                    dispatch(hideLoader(loaderRef));
                    setDataLoaded(true);
                })
                .catch((responseError) => {
                    dispatch(hideLoader(loaderRef));
                    notification['error']({
                        message: CUSTOMER_LIST,
                        description: DATA_FETCHING_ERROR,
                    });
                });
        }
    };


    const handelSaveAndContinueUpdate = () => {
        if (serveyData) {
            serveyData["previous_process_status"] = serveyData?.process_status;
            serveyData["process_status"] = "Processed";
        }
        handelStatusConfirmation();
    };

    const handelMaybeDuplicateUpdate = () => {
        if (serveyData) {
            serveyData["previous_process_status"] = serveyData?.process_status;
            serveyData["process_status"] = "Probable Duplicate";
        }
        handelStatusConfirmation();
    };

    const handelIrreleventUpdate = () => {
        if (serveyData) {
            serveyData["previous_process_status"] = serveyData?.process_status;
            serveyData["process_status"] = "Irrelevent";
        }
        handelStatusConfirmation();
    };

    const handelToReviewupdate = () => {
        if (serveyData) {
            serveyData["previous_process_status"] = serveyData?.process_status;
            serveyData["process_status"] = "To Review";
        }
        handelStatusConfirmation();
    };

    const handleCloseConfirmationModel = () => {
        confirm({
            title: "Confirmation",
            content: DATA_LOST_INFORMATION,
            onOk() { onClosePopUpConfirmOk() },
        });
    }

    const onClosePopUpConfirmOk = () => {
        navigate(CUSTOMER);
    };

    const handelClose = () => {
        handleCloseConfirmationModel();
    };

    const handelPrevious = () => {
        getPreviousServeyDetails();
    };

    const handelRawIDValue = (e: any) => {
        setGotoRawID(e.target.value);
    }

    const renderCustomerDeatilsHeader = () => {
        return (
            <div className="d-flex justify-content-between border-bottom mb-1">
                <p className="customer-heading">Data Validation From: <span className="customer-details">
                    {rawIdData ? serveyData.customer_id : location?.state?.rowData?.customer_id} - {rawIdData ? serveyData.name : location?.state?.rowData?.name}
                </span></p>
                <p className='customer-details'>
                    <span className={serveyData?.process_status?.toLowerCase().replace(/ /g, "")}>
                        {serveyData?.process_status}
                    </span> Remaing records:{headerData?.remaining_records} of {headerData?.total_records}
                </p>
            </div>
        )
    };

    const renderCustomerDetailsForm = () => {
        return (
            <Form
                layout={'horizontal'}
                labelCol={{
                    span: 8
                }}
                wrapperCol={{
                    span: 18
                }}
                labelAlign="right"
                form={form}
            >
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='rawid'
                            label="Raw ID"
                        >
                            <Input
                                type={'text'}
                                placeholder={'Raw ID'}
                                disabled={true}
                                defaultValue={serveyData?.rawid}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='process_datetime'
                            label="Process Date"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Process Date'}
                                disabled={true}
                                defaultValue={moment(serveyData?.process_datetime).format(DateFormat.Date)}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='updated_time'
                            label="Last Update"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Last Update'}
                                disabled={true}
                                defaultValue={moment(serveyData?.updated_time).format(DateFormat.Date)}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='source_name'
                            label="Source Name"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Source Name'}
                                disabled={true}
                                defaultValue={serveyData?.source_name}

                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='date_value'
                            label="Review Date"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Review Date'}
                                disabled={true}
                                defaultValue={moment(serveyData?.date).format(DateFormat.Date)}
                            />
                        </Form.Item >

                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='commentedby'
                            label="Review By"
                        >
                            <a>{serveyData?.responder?.commentedby}</a>
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>

                        <Form.Item
                            className='input-field'
                            name='review_title'
                            label="Review Title"
                        >
                            <a>{serveyData?.review_title}</a>
                        </Form.Item >
                    </div>
                </div>
            </Form>
        )
    };

    const renderButtons = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className=''>
                    <Button type="primary" className='' onClick={handelMaybeDuplicateUpdate}>May be duplicate</Button>
                    <Button type="primary" onClick={handelIrreleventUpdate}>Irrelevant</Button>
                    <Button type="primary" onClick={handelToReviewupdate}>To Review</Button>
                </div>
                <div className='d-flex'>
                    <div className=''>
                        <Form
                            className='mt-1'
                            layout="horizontal"
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Raw ID"
                            >
                                <Input
                                    value={gotoRawID}
                                    onChange={handelRawIDValue}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Button type="primary" onClick={getServeyDetailsWithRawID} >Goto Raw ID</Button>
                    <Button type="primary" onClick={handelPrevious}>Previous</Button>
                    <Button type="primary" onClick={handelSaveAndContinueUpdate}>{'Save & Continue'}</Button>
                    <Button type="primary" onClick={handelClose}>Close</Button>
                </div>
            </div>
        )
    };

    const rendereditCustomerDetails = () => {
        return (
            <div className='customer-details-edit scrollable-page-area p-2'>
                {renderCustomerDeatilsHeader()}
                {renderCustomerDetailsForm()}
                <SurveyResponse
                    surveyQuestions={serveyData?.responses}
                />
                {renderButtons()}
                {serveyData?.verified_by &&
                    <p>Verified By: <span className='verify'>{serveyData?.verified_by} </span> as on <span className='verify'>{moment(serveyData?.verified_time).format(DateFormat.YYYY_MM_DD_hh_mm_ss)}</span> </p>
                }
            </div>
        )
    }


    return (dataLoaded && rendereditCustomerDetails())
};

export default EditCustomerDeatils;