export enum DateFormat {
	Date = 'DD-MMM-YYYY',
	DateAndTime = 'DD-MMM-yyyy hh:mm a',
	DDMMYYYY = 'DDMMYYYY',
	MMM_YY = 'MMM\'YY',
	WEEK_RANGE_FORMAT = '[WK]WW\'YY',
	YYYYMM = 'YYYYMM',
	YYYYMMDD = 'YYYYMMDD',
	YYYYWW = 'YYYYWW',
	YYYY_MM_DD = 'YYYY-MM-DD',
	DateAndTimeSeconds = 'DD-MMM-yyyy hh:mm:ss a',
	MMM_DD_YYYY = "MMM DD YYYY",
	MMM_DD_YY = "MMM DD'YY",
	DD_MMM_YY = "DD-MMM-YY",
	YYYY_MM_DD_hh_mm_ss = 'yyyy-MM-DD hh:mm:ss '
	
}