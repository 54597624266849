import './survey-response.component.scss';

import { InputNumber, Modal, Switch, Tabs } from "antd";
import React, { useEffect, useState } from "react";

import { BooleanEnum } from '../../../../enum/boolean.enum';
import Classifier from "../rawdata-classifier/rawdata-classifier.component";
import { Collapse } from 'react-bootstrap';
import TatvamRating from '../../../functional-components/tatvam-rating/tatvam-rating.component';
import TextArea from 'antd/es/input/TextArea';
import UserRating from '../../../functional-components/user-rating/user-rating.component';
import { CANCEL, NOTE, SAVE } from '../../../../constants/title';

type SurveyResponseProps = {
    surveyQuestions: any[];
};

const SurveyResponse: React.FC<SurveyResponseProps> = (props: SurveyResponseProps) => {
    const [surveyTatvamRatingQuestions, setSurveyTatvamRatingQuestions] = useState([]);
    const [otherSurveyQuestions, setOtherSurveyQuestions] = useState([]);
    const [isQuestionsCollapse, setIsQuestionsCollapse] = useState(false as boolean);
    const [questionVisible, setQuestionVisible] = useState({} as any);
    const [isoverallQuestionRating, setIsoverallQuestionRating] = useState(false as boolean);
    const [isCheckedText, setIsCheckedText] = useState(true as boolean);
    const [isModelOpen, setIsModelOpen] = useState(false as boolean);
    const [isCurrentQuestion, setIsCurrentQuestion] = useState({} as string);

    useEffect(() => {
        if (!props?.surveyQuestions) {
            setSurveyTatvamRatingQuestions([]);
        } else {
            const tatvamQuestion: any = [];
            const otherQuestions: any = [];
            props?.surveyQuestions?.forEach((question) => {
                if (question?.tatvam_rating > 0 || question?.annotations?.length > 0 && question?.annotations?.tatvam_rating > 0) {
                    tatvamQuestion.push(question);
                } else {
                    otherQuestions.push(question);
                }
            });

            const surveyTatvamQuestionCollapse = tatvamQuestion?.reduce((a: any, v: any, index: any) => {
                return ({ ...a, [v.question_id]: true });
            }, {});
            setIsCheckedText(true);
            setOtherSurveyQuestions(otherQuestions);
            isSurveyQuestionCollpase(otherQuestions);
            setSurveyTatvamRatingQuestions(tatvamQuestion);
            setQuestionVisible(surveyTatvamQuestionCollapse);
        }

    }, [props?.surveyQuestions]);

    const isCollapse = (question_id: string) => {
        setQuestionVisible((prev: any) => {
            return { ...prev, [question_id]: !prev[question_id] };
        });
    };

    const handelText = (checked: boolean) => {
        setIsCheckedText(checked);
    }

    const isSurveyQuestionCollpase = (surveyRuestion: any) => {
        if (!surveyRuestion?.length) {
            setIsQuestionsCollapse(true);
        } else {
            setIsQuestionsCollapse(false);
        }
    };

    const renderOtherQuestionCollapse = () => {
        return (
            <div className='row p-2'>
                {otherSurveyQuestions?.length ? <>
                    <div className='heading'>
                        <i aria-hidden="true" className={isQuestionsCollapse ? 'fas fa-caret-down ms-2 me-2 mb-2' : 'fas fa-caret-right ms-2 me-2 mb-2'}
                            onClick={() => setIsQuestionsCollapse(!isQuestionsCollapse)}></i>
                        Other Survey Questions
                    </div>
                </> : <></>
                }
                {otherSurveyQuestions?.map((question: any, index) => {
                    return (
                        <Collapse key={question?.question_id} in={isQuestionsCollapse}>
                            <div className="other-questions mb-2 ms-5 p-0">
                                <p className='mb-1'>{question?.question_text} - <span className='question-answer'>{question?.response_text}</span></p>
                            </div>
                        </Collapse>
                    )
                })}
            </div>
        )
    };

    const renderRatings = (rating: any) => {
        return (
            <div className='d-flex p-0'>
                <UserRating
                    isDisabled={true}
                    value={rating?.user_rating}
                    text={'User Rating : '}
                />
                <TatvamRating
                    value={rating}
                    isDisable={true}
                    text={'Tatvam Rating : '}
                    ratingType={rating?.previous_tatvam_rating}
                />
                <TatvamRating
                    value={rating}
                    isDisable={true}
                    text={'Updated Tatvam Rating : '}
                    ratingType={rating?.tatvam_rating}
                />
            </div>
        )
    };

    const handelAnnotationsData = () => {
        setIsoverallQuestionRating(!isoverallQuestionRating)
    };

    const renderTabs = (data: any) => {
        const type: any = [];
        data?.annotations.map((obj: any) => {
            if (!type.includes(obj.key_type)) {
                if (obj.key_type.toLowerCase() === "CLASSIFIER".toLowerCase()) {
                    type.unshift(obj.key_type);
                }
                else {
                    type.push(obj.key_type);
                }
            }
        });
        const tabData: any = type.map((tab: string) => {
            return {
                label: tab,
                key: tab.toLocaleLowerCase(),
                children: (
                    <Classifier
                        data={data?.annotations.filter((ele: any) => ele.key_type === tab)}
                        handelAnnotationsData={handelAnnotationsData}
                    />
                )
            }
        })
        return <div className='row p-2 pb-0 pt-0'>
            <Tabs defaultActiveKey="classifier"
                items={tabData} />
        </div>
    };

    const handelNoteSave = () => {
        setIsModelOpen(false);
    }

    const handelNoteCancel = () => {
        setIsModelOpen(false);
    }


    const renderRatingTextBoxs = (data: any) => {
        let classifierTagCount: number = 0;
        let originalTatvamRating: number = 0;
        let updatedTatvamRating: number = 0;

        data?.annotations.map(function (oneAnnotation: any) {
            if (oneAnnotation.process_status.toLowerCase() != "deleted" && oneAnnotation.key_type.toLowerCase() === "classifier") {
                originalTatvamRating = originalTatvamRating + oneAnnotation.previous_tatvam_rating;
                updatedTatvamRating = updatedTatvamRating + oneAnnotation.tatvam_rating;
                classifierTagCount++;
            }
        });
        if (originalTatvamRating != 0) {
            originalTatvamRating = originalTatvamRating / classifierTagCount;
        }
        if (updatedTatvamRating != 0) {
            updatedTatvamRating = updatedTatvamRating / classifierTagCount;
        }

        return (
            <div className='d-flex p-2'>
                <p className='rating-label mb-0 me-2'>Avg Orig Tatvam Rating : </p>
                <InputNumber
                    disabled={true}
                    value={originalTatvamRating.toFixed(2)}
                    size={'small'}
                    controls={false}
                />
                <p className='rating-label mb-0 me-2'>Avg Updated Tatvam Rating : </p>
                <InputNumber
                    disabled={true}
                    value={updatedTatvamRating.toFixed(2)}
                    size={'small'}
                    controls={false}
                />
            </div>
        )
    };

    const handelNote = (question: any) => {
        setIsCurrentQuestion(question);
        setIsModelOpen(true);
    };

    const renderModel = (question: any) => {
        return (
            <Modal
                title={NOTE}
                open={isModelOpen}
                onOk={handelNoteSave}
                onCancel={handelNoteCancel}
                okText={SAVE}
                cancelText={CANCEL}
                closable={false}
                keyboard={false}
                maskClosable={false}
            >
                {handelNoteText(question)}
            </Modal>
        )
    }

    const handelNoteText = (question: any) => {
        const handelValueChange = (e: any) => {
            question["notes"] = e.target.value;
        };
        return (
            <TextArea rows={6} defaultValue={question.notes} onChange={handelValueChange} />
        )
    }

    const renderLikeAndComment = (interested: string, notes: string, questions: any) => {
        let interested1 = questions.interested ?? "No";
        const handleInterest = (event: any, value: any) => {
            if (value === BooleanEnum.NO) {
                questions.interested = BooleanEnum.YES;
                interested1 = BooleanEnum.YES;
                event.currentTarget.classList.remove("fa-regular", "like");
                event.currentTarget.classList.add("fa-solid", "dis-like");
            } else {
                event.currentTarget.classList.add("fa-regular", "like");
                event.currentTarget.classList.remove("fa-solid", "dis-like");
                questions.interested = BooleanEnum.NO;
                interested1 = BooleanEnum.NO;
            }
        };

        return (
            <div className='d-flex flex-column justify-content-between ms-1'>
                <i aria-hidden="true" className={`${questions.interested === 'Yes' ? "fa-solid fa-thumbs-up dis-like" : "fa-regular fa-thumbs-up like"}`}
                    data-interested={`${questions.interested}`} onClick={(e) => handleInterest(e, interested1)}></i>
                <i aria-hidden="true" className="fa-solid fa-note-sticky note" onClick={() => {
                    handelNote(questions);
                }}>
                </i>
            </div>
        )
    };

    const renderTatvamQuestions = () => {
        return (
            <>
                {surveyTatvamRatingQuestions?.map((question: any, index: number) => {
                    return (
                        <div className="row p-3 pb-0 border-bottom pt-2" key={question?.question_id}>
                            <p className='mb-0'>
                                <i aria-hidden="true" className={!questionVisible[question?.question_id] ?
                                    'fas fa-caret-right ml-1 mr-1' :
                                    'fas fa-caret-down ml-1 mr-1'
                                }
                                    onClick={() => isCollapse(question?.question_id)}></i>
                                <span className="question-heading">{question?.question_text}</span>
                                <Switch
                                    className='float-end mb-2'
                                    checkedChildren={question?.source_language === "en" ? "English Text" : "Original Text"}
                                    unCheckedChildren={question?.source_language !== "en" ? "English Text" : "Original Text"}
                                    onClick={handelText}
                                    checked={isCheckedText}
                                />
                            </p>
                            <Collapse in={questionVisible[question?.question_id]}>
                                <div id="">
                                    <div className='d-flex'>
                                        <p className="question-answer mb-0 border">
                                            {isCheckedText ? question?.response_text : question?.response_original_text}
                                        </p>
                                        {renderLikeAndComment(question?.interested, question?.notes, question)}
                                    </div>
                                    {renderRatings(question)}
                                    {renderTabs(question)}
                                    {renderRatingTextBoxs(question)}

                                </div>
                            </Collapse>
                        </div>
                    )
                })}
            </>
        )
    };

    const renderComponent = () => {
        return (
            <div className='survey-response'>
                {renderTatvamQuestions()}
                {renderOtherQuestionCollapse()}
                {isModelOpen && renderModel(isCurrentQuestion)}
            </div>
        )
    };

    return renderComponent();

};

export default SurveyResponse;