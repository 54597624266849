import { IAccount, IProfile } from '../models/account.interface';

export const LOG_IN: string = 'LOG_IN';
export const LOG_OUT: string = 'LOG_OUT';
export const SET_USER_PROFILE: string = 'SET_USER_PROFILE';

export function login(account: IAccount): ILogInActionType {
	return { type: LOG_IN, account };
}

export function logout(isSessionExpired: boolean = false): ILogOutActionType {
	return { type: LOG_OUT, value: isSessionExpired };
}

export function setUserProfile(userProfile: IProfile): ISetProfileActionType {
	return { type: SET_USER_PROFILE, userProfile: userProfile };
}

interface ILogInActionType {
	type: string;
	account: IAccount;
}

interface ISetProfileActionType {
	type: string;
	userProfile: IProfile;
}

interface ILogOutActionType {
	type: string;
	value: boolean
}
