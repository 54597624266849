import React from 'react';
import HomePage from './components/home-page.component';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './shared/components/PrivateRoute';
import Login from './components/application-components/account/login.component';

const App: React.FC<any> = () => {
    return (
        <div className='App'>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path="/private/*" element={<HomePage />} />
                </Route>
                <Route path='/'element={<Login />} />
            </Routes>
        </div>
    );
}

export default App;
