export const SHOW_LOADER: string = 'SHOW_LOADER';
export const HIDE_LOADER: string = 'HIDE_LOADER';
export const CLEAR_LOADERS: string = 'CLEAR_LOADERS';

export function showLoader(callingFrom: string): ILoaderActionType {
	return { type: SHOW_LOADER, callingFrom };
}

export function hideLoader(callingFrom: string): ILoaderActionType {
	return { type: HIDE_LOADER, callingFrom };
}

export function clearLoaders(): ILoaderActionType {
	return { type: CLEAR_LOADERS, callingFrom: '' };
}

interface ILoaderActionType {
	type: string;
	callingFrom: string;
}
