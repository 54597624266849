import { KeyValue } from '@custom-types/key-value.type';

class ObjectUtility {
	public static getObject(name: string, value: any) {
		const returnObj: any = {};
		returnObj[name] = value;
		return returnObj;
	}

	// This function will return nested property as well as single property value.
	// Expecting nested property should be passed to column args with delimeiter(.)
	public static readonly getObjectPropValue = (data: any, column: string) => {
		//const keys = column.split('.');//temply removed
		const keys = column.indexOf(".") ? [column] : column.split('.');
		let result: any = data;
		for (const prop of keys) {
			result = result?.[prop];
		}
		return result;
	};

	public static readonly setObjectPropValue = (
		column: string,
		row: any,
		value: any,
	): any => {
		let schema = row; // a moving reference to internal objects within obj
		const pList = column.split('.');
		const len = pList.length;
		for (let i = 0; i < len - 1; i++) {
			const elem = pList[i];
			if (!schema[elem]) schema[elem] = {};
			schema = schema[elem];
		}

		schema[pList[len - 1]] = value;

		return schema;
	};

	public static convertToObject(fields: KeyValue<string, any>[]) {
		const returnObj: any = {};
		fields.forEach(keyValue => {
			returnObj[keyValue.key] = keyValue.value;
		});
		return returnObj;
	}
}

export default ObjectUtility;
