import { StatusCodes } from 'http-status-codes';
import ApiService from './api.service';
import { HttpMethodType } from '../enum/http-method-type.enum';
import {
    URL_ACCURACY_TREND,
    URL_GET_ALL_CUSTOMERS,
    URL_OVERALL_ACCURACY,
    URL_RESOURCE_TREND
} from '../constants/url';

export class ReportServices {

    private _getCustomers: string = URL_GET_ALL_CUSTOMERS;
    private _getAccuracyTrend: string = URL_ACCURACY_TREND;
    private _getResourceTrend: string = URL_RESOURCE_TREND;
    private _getOverallAccuracy:string=URL_OVERALL_ACCURACY

    public getAllCustomers = async () => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(this._getCustomers);
        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

    public getAccuracyTrend = async (selectCustomerID: any) => {
        let accuracyResult: any[] = [];
        const endPoint =  this._getAccuracyTrend.replace('{customer_id}', selectCustomerID);
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(endPoint)
        if (response && response.status === Number(StatusCodes.OK)) {
            accuracyResult = response?.data?.data;
        }
        return accuracyResult;
    };

    public getResourceContribution = async () => {
        let resourceContributionResult: any[] = [];
        const endPoint = this._getResourceTrend;
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(endPoint);
        if (response && response.status === Number(StatusCodes.OK)) {
            resourceContributionResult = response?.data?.data;
        }
        return resourceContributionResult;
    };

    public getOverallAccuracy= async(selectCustomerID:any)=>{
        let overallAccuracyResult:any[]=[];
        const endPoint = this._getOverallAccuracy.replace('{customer_id}', selectCustomerID);
        const apiService=new ApiService();
        apiService.method=HttpMethodType.Get;
        const response = await apiService.send(endPoint)
        if(response && response.status === Number(StatusCodes.OK)){
            overallAccuracyResult=response?.data?.data;
        }
        return overallAccuracyResult;
    };

}