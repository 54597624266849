import React from "react";
import { SizeMe } from "react-sizeme";
import _ from "lodash";

import WidgetCard from "../../widgetCard/widget-card.component";
import ChartHelper from "../../../../reports/chart-helper";
import Plotly, { Layout, PlotData } from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ResourceContributionTypes } from "../../../../types/resource-contribution-types";

const Plot = createPlotlyComponent(Plotly);

type ResourceContributionProps = {
    datas: ResourceContributionTypes[];
    title: string;
};

const ResourceContributionTrend: React.FC<ResourceContributionProps> = (
    props: ResourceContributionProps
) => {
    const { datas, title } = props;
    const colorCode: { bar: any } = { bar: {} };
    const xAxisDatas: any[] = [];
    const xAxisLabels: any[] = [];
    const yAxisRanges: any[] = [];

    const getChartData = (): Partial<PlotData>[] => {
        let chartDatas: any[] = [];

        if (datas) {
            const groupedByResource: any = _.groupBy(datas, (data: ResourceContributionTypes) => data.verified_by);
            const groupedByDate: any = _.groupBy(datas, (data: ResourceContributionTypes) => data.week_year);

            if (groupedByDate) {
                const dateLabels: string[] = Object.getOwnPropertyNames(groupedByDate);
                dateLabels.forEach((key: string) => {
                    xAxisLabels.push(key);
                });
            }
            if (groupedByResource) {
                let currentIndex: number = 0;
                const resourceNames: string[] = Object.getOwnPropertyNames(groupedByResource).sort((a, b) => a.localeCompare(b));

                resourceNames.forEach((key: string) => {
                    const yAxisData: any[] = [];

                    let resourceTypes: ResourceContributionTypes[] = groupedByResource[key];
                    resourceTypes.forEach((type: ResourceContributionTypes) => {
                        xAxisDatas.push(type.week_year);
                        yAxisData.push(type.count);
                        yAxisRanges.push(type.count);
                    });
                    if (currentIndex < ChartHelper.getResourceColors().length) {
                        colorCode.bar[key] = ChartHelper.getResourceColors()[currentIndex];
                        currentIndex = currentIndex + 1;
                    }
                    chartDatas.push(ChartHelper.PrepareStackDataLayout(
                        xAxisLabels,
                        yAxisData,
                        key,
                        colorCode.bar[key],
                        `${key}<br>(%{x}, %{y:,})<extra></extra>`,
                        'v'
                    ));
                })
            }
        }
        return chartDatas;
    };

    const getChartLayout = (): Partial<Layout> => {
        const yAxisTickVals = yAxisRanges.filter((y: number) => y >= 5).length <= 0 ? [0, 5] : [];
        const groupedYValues = Object.values(datas.reduce((data: any, resource: any) => (data[resource.week_year]
            ? data[resource.week_year].count += resource.count
            : (data[resource.week_year] = { ...resource }), data), {}));

        const maxYValue = Math.max(...groupedYValues.map((resource: any) => resource?.count));
        const layout = ChartHelper.PrepareStackChartLayout(
            `<b>Contribution Trend</b>`,
            '<b>Contribution Percentage</b>',
            yAxisTickVals,
            true,
            undefined,
            xAxisLabels,
            false,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            'category',
            undefined,
            maxYValue
        );

        return {
            ...layout,
            legend: {
                x: 0.5,
                y: 1.2,
                orientation: 'h',
                xanchor: 'center',
                traceorder: 'normal',
            },
        };
    };

    const renderChart = () => {
        if (!datas.length) {
            return (
                <h3 className="widget-card-no-data">No Data</h3>
            );
        }
        return (
            <SizeMe>{() =>
                <Plot
                    data={getChartData()}
                    layout={getChartLayout()}
                    config={{ displayModeBar: false }}
                    onLegendDoubleClick={() => false}
                    onLegendClick={() => false}
                    className="plotly-chart"
                />
            }
            </SizeMe>
        )
    }

    return (
        <WidgetCard
            title={title}
            childComp={renderChart()}
        />
    )
}

export default ResourceContributionTrend;