export const SET_APP_VERSION: string = 'SET_APP_VERSION';
export const CLEAR_APP_VERSION: string = 'CLEAR_APP_VERSION';

export function setAppVersion(version: string): IAppVersionType {
    return { type: SET_APP_VERSION, value: version };
}


interface IAppVersionType {
    type: string;
    value: string;
}
