import widgetStyles from "../widgetCard/widget-card.module.scss";

import React from "react";

type widgetCardType = {
    title?: string;
    titleComp?: React.ReactNode;
    value?: string;
    childComp?: React.ReactNode;
};

const WidgetCard: React.FC<widgetCardType> = (props: widgetCardType) => {
    return (
        <div className={widgetStyles.widgetCard}>
            {props.title && (
                <div className={widgetStyles.widgetHeader}>
                    <h5> {props.title} </h5>
                </div>
            )}
            {props.titleComp && <div className={widgetStyles.widgetHeader}>{props.titleComp}</div>}

            <div className={widgetStyles.widgetBody}>
                {props?.childComp && <div>{props?.childComp}</div>}
                <h5 className={widgetStyles.bodyText}>{props.value}</h5>
            </div>
        </div>
    )
}

export default WidgetCard;