import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./nav-bar.component.scss";
import { CUSTOMER, DASHBOARD, REPROCESS, RESOURCE_MANAGEMENT, SEARCH_RAW_DATA } from "../../../../constants/routes";
import { useSelector } from "react-redux";
import { IAccount, IStateType } from "../../../../store/models";
import { Modal } from "antd";
import { LOGOUT_CONFIRMATION } from "../../../../constants/messages";

const NavBar: React.FC = () => {
    const account: IAccount = useSelector((state: IStateType) => state?.account);
    const { confirm } = Modal;
    const navigate = useNavigate();
    const [selectedLink, setSelectedLink] = useState('customer');

    const onConfirmationOk = () => {
        navigate("/private/logout");
    }

    const handelLogOut = () => {
        confirm({
            title: "Confirmation",
            content: LOGOUT_CONFIRMATION,
            onOk() { onConfirmationOk() },
        });
    }

    const handleLinkClick = (link: string) => {
        setSelectedLink(link);
      };

    return (
        <div className='navBar'>
            <nav>
                <div className='navBarLinks'>
                    <Link className={selectedLink === 'dashboard' ? 'active-menu' : ''} onClick={() => handleLinkClick('dashboard')} to={DASHBOARD} >
                        Dashboard
                    </Link>
                    <Link className={selectedLink === 'customer' ? 'active-menu' : ''} onClick={() => handleLinkClick('customer')} to={CUSTOMER} >
                        Customer
                    </Link>
                    <Link className={selectedLink === 'resource_management' ? 'active-menu' : ''} onClick={() => handleLinkClick('resource_management')} to={RESOURCE_MANAGEMENT} >
                        Resource Management
                    </Link>
                    <Link className={selectedLink === 'search_rawdata' ? 'active-menu' : ''} onClick={() => handleLinkClick('search_rawdata')} to={SEARCH_RAW_DATA}>
                        Search Rawdata
                    </Link>
                    <Link className={selectedLink === 'reprocess' ? 'active-menu' : ''} onClick={() => handleLinkClick('reprocess')} to={REPROCESS} >
                        Reprocess
                    </Link>
                    <Link className="float-end" onClick={handelLogOut}>
                        {account?.first_name} {<i className="fa fa-sign-out" aria-hidden="true"></i>}
                    </Link>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;