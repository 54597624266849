import React, { Dispatch, useEffect, useState } from 'react';
import { AntGridFilterType } from '../../../enum/antd-grid-filter-type.enum';
import { DateFormat } from '../../../enum/date-format.enum';
import antdFilter from '../../../shared/components/antd-table/antd-filter.function';
import { sorterFunction, sortingDirections } from '../../../shared/components/antd-table/sorter.function';
import { Table, notification } from 'antd';
import { RESOURCE_MANAGEMENT_TITLE } from '../../../constants/title';
import { ResourceMangementService } from '../../../services/resource-management.service';
import { RESOURCE_MANAGEMENT } from '../../../constants/routes';
import { DATA_FETCHING_ERROR } from '../../../constants/messages';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/actions/loader.actions';

const ResourceManagement: React.FC<any> = () => {

    const [gridData, setGridData] = useState([] as any);
    const [sortedInfo, setSortedInfo] = useState('' as any);
    const [filteredInfo, setFilteredInfo] = useState(null as any);

    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        getResourceWiseCount();
    }, [])

    const resourceManagementService = new ResourceMangementService();
    const loaderRef: string = 'Get Resource Management count';
    dispatch(showLoader(loaderRef));

    const getResourceWiseCount = () => {
        resourceManagementService.getResourceWiseCount()
            .then((response: any) => {
                setGridData(response);
                dispatch(hideLoader(loaderRef));
            })
            .catch((responseError) => {
                dispatch(hideLoader(loaderRef));
                notification['error']({
                    message: RESOURCE_MANAGEMENT,
                    description: DATA_FETCHING_ERROR,
                });
            })

    }

    const allTitles = gridData.reduce((keys: any, obj: any) => {
        Object.keys(obj).forEach(key => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        }); return keys;
    }, []);
    const columnDef = allTitles.map((key: any) => {
        if (key === "process_date") {
            return {
                title: 'Date',
                dataIndex: key,
                key: key,
                width: "220px",
                filteredValue: filteredInfo?.key || null,
                sortOrder: sortedInfo?.field === key && sortedInfo?.order,
                showSorterTooltip: true,
                sorter: (a: string, b: string) => sorterFunction(a, b, key, 'string'),
                sortDirections: sortingDirections,
                ...antdFilter(key, AntGridFilterType.SearchBox, gridData),
                render: (record: any) => (
                    <span>{moment(record).format(DateFormat.DD_MMM_YY)}</span>
                )
            }
        } else if (key === "total_count") {
            return {
                title: 'Total Rec#',
                dataIndex: key,
                key: key,
                width: "220px",
                filteredValue: filteredInfo?.key || null,
                sortOrder: sortedInfo?.field === key && sortedInfo?.order,
                showSorterTooltip: true,
                sorter: (a: string, b: string) => sorterFunction(a, b, key, 'number'),
                sortDirections: sortingDirections,
                ...antdFilter(key, AntGridFilterType.SearchBox, gridData),
            }
        } else {
            return {
                title: key,
                dataIndex: key,
                key: key,
                filteredValue: filteredInfo?.key || null,
                sortOrder: sortedInfo?.field === key && sortedInfo?.order,
                showSorterTooltip: true,
                sorter: (a: string, b: string) => sorterFunction(a, b, key, 'number'),
                sortDirections: sortingDirections,
                ...antdFilter(key, AntGridFilterType.SearchBox, gridData),
                render: (record: any) => (
                        <span>{record || 0}</span>
                )
            }
        }
    });

    const columnDefNoData = [
        {
            title: 'Date',
            dataIndex: '',
            key: ''
        },
        {
            title: 'Total Rec#',
            dataIndex: '',
            key: ''
        }
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any,
    ) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const renderTable = () => {
        return (

            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 border border-dark ">
                <div className="row p-2">
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                        <label className={` col-lg-6 col-md-3 col-sm-6 col-xs-12 p-0 ms-2 mb-2`}>
                            {RESOURCE_MANAGEMENT_TITLE}
                        </label>
                    </div>

                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Table
                            columns={gridData.length > 0 ? columnDef : columnDefNoData}
                            dataSource={gridData}
                            bordered={true}
                            pagination={false}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className='customers scrollable-page-area p-2'>
            {renderTable()}
        </div>
    )
}
export default ResourceManagement;
