import { IActionBase } from '../models/root.interface';
import { IVersion } from '../models/version.interface';
import { SET_APP_VERSION, CLEAR_APP_VERSION } from '../actions/version.action';

const initialState: IVersion = {
    version: "",
};

function versionReducer(
    state: IVersion = initialState,
    action: IActionBase,
): IVersion {
    switch (action.type) {
        case SET_APP_VERSION: {
            return { ...state, version: action.value };
        }
        case CLEAR_APP_VERSION: {
            return { ...state, version: action.value };
        }
        default:
            return state;
    }
}

export default versionReducer;
