import './search-rawdata.component.scss';

import React, { useState } from "react";
import { Button, Form, Input, Modal, notification } from 'antd';
import SurveyResponse from './survey-response/survey-response.component';
import { CustomerService } from '../../../services/customer.service';
import { CUSTOMER_LIST, CUSTOMER_RAWID_EMPTY, CUSTOMER_WITH_RAWID, DATA_FETCHING_ERROR, NO_USER_WITH_RAWID, SEARCH_RAWID } from '../../../constants/messages';
import moment from 'moment';
import { DateFormat } from '../../../enum/date-format.enum';

const SearchRawDeatils: React.FC<any> = (props: any) => {
    const [dataLoaded, setDataLoaded] = useState(false as boolean);
    const [headerData, setHeaderData] = useState({
        remaining_records: 0,
        total_records: 0
    })
    const [serveyData, setServeyData] = useState({} as any);
    const customerService = new CustomerService();
    const [form] = Form.useForm();
    const [gotoRawID, setGotoRawID] = useState("" as string);

    const getServeyDetailsWithRawID = () => {
        if (gotoRawID.length === 0 || gotoRawID.trim() === "") {
            Modal.warning({
                title: "Alert",
                content: CUSTOMER_RAWID_EMPTY,
                onOk: () => { setGotoRawID("") }
            });
        }
        else {
            customerService.getCustomerDataByRawID(gotoRawID)
                .then((response: any) => {
                    if (Object.keys(response)?.length > 0) {
                        setGotoRawID("");
                        setServeyData(response?.data);
                        form.resetFields();
                        setHeaderData(
                            {
                                remaining_records: response?.remaining_records,
                                total_records: response?.total_records
                            }
                        );
                        notification['success']({
                            message: SEARCH_RAWID,
                            description: CUSTOMER_WITH_RAWID,
                        });
                        setDataLoaded(true);
                    } else {
                        Modal.warning({
                            title: "Alert",
                            content: NO_USER_WITH_RAWID,
                            onOk: () => { setGotoRawID("") }
                        });
                    }
                    setDataLoaded(true);
                })
                .catch((responseError) => {
                    setHeaderData(
                        {
                            remaining_records: 0,
                            total_records: 0
                        }
                    )
                    notification['error']({
                        message: CUSTOMER_LIST,
                        description: DATA_FETCHING_ERROR,
                    });
                });
        }
    };

    const handelRawIDValue = (e: any) => {
        setGotoRawID(e.target.value);
    };

    const handelSearchRawId = () => {
        getServeyDetailsWithRawID();
    };

    const renderCustomerDeatilsHeader = () => {
        return (
            <div className="d-flex justify-content-between border-bottom mb-1">
                <p className="customer-heading">Data Validation From: <span className="customer-details">
                    {serveyData?.customer_id} - {serveyData?.name}
                </span></p>
                <p className='customer-details'>
                    <span className={serveyData?.process_status?.toLowerCase().replace(/ /g, "")}>
                        {serveyData?.process_status}
                    </span> Remaing records:{headerData?.remaining_records} of {headerData?.total_records}
                </p>
            </div>
        )
    };

    const renderRawId = () => {
        return (
            <div className='col-lg-12 col-md-12 col-sm- 12 col-xs-12'>
                <div className='rawid-textbox'>
                    <Form
                        className='mt-1'
                        layout="horizontal"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Raw ID"
                        >
                            <Input
                                className='text-box'
                                value={gotoRawID}
                                onChange={handelRawIDValue}
                            />
                        </Form.Item>
                    </Form>
                    <Button className="ms-2" type="primary" onClick={handelSearchRawId} >Search</Button>
                </div>
            </div>
        );
    }


    const renderCustomerDetailsForm = () => {
        return (
            <Form
                layout={'horizontal'}
                labelCol={{
                    span: 8
                }}
                wrapperCol={{
                    span: 18
                }}
                labelAlign="right"
                form={form}
            >
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='rawid'
                            label="Raw ID"
                        >
                            <Input
                                type={'text'}
                                placeholder={'Raw ID'}
                                disabled={true}
                                defaultValue={serveyData?.rawid}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='process_datetime'
                            label="Process Date"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Process Date'}
                                disabled={true}
                                defaultValue={moment(serveyData?.process_datetime).format(DateFormat.Date)}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='updated_time'
                            label="Last Update"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Last Update'}
                                disabled={true}
                                defaultValue={moment(serveyData?.updated_time).format(DateFormat.Date)}
                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='source_name'
                            label="Source Name"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Source Name'}
                                disabled={true}
                                defaultValue={serveyData?.source_name}

                            />
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='date_value'
                            label="Review Date"
                        >

                            <Input
                                type={'text'}
                                placeholder={'Review Date'}
                                disabled={true}
                                defaultValue={serveyData?.response_date?.date_value}
                            />
                        </Form.Item >

                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                        <Form.Item
                            className='input-field'
                            name='commentedby'
                            label="Review By"
                        >
                            <a>{serveyData?.responder?.commentedby}</a>
                        </Form.Item >
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>

                        <Form.Item
                            className='input-field'
                            name='review_title'
                            label="Review Title"
                        >
                            <a>{serveyData?.review_title}</a>
                        </Form.Item >
                    </div>
                </div>
            </Form>
        )
    };

    const rendereditCustomerDetails = () => {
        return (
            <>
                {renderCustomerDeatilsHeader()}
                {renderCustomerDetailsForm()}
                <SurveyResponse
                    surveyQuestions={serveyData?.responses}
                />
                {serveyData?.verified_by &&
                    <p>Verified By: <span className='verify'>{serveyData?.verified_by} </span> as on <span className='verify'>{moment(serveyData?.verified_time).format(DateFormat.YYYY_MM_DD_hh_mm_ss)}</span> </p>
                }
            </>
        )
    };


    return (
        <div className='customer-details-edit scrollable-page-area p-2'>
            {renderRawId()}
            {dataLoaded && rendereditCustomerDetails()}
        </div>
    )
};

export default SearchRawDeatils;