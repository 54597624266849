import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CustomerDetails from './application-components/customer/customer.component';
import Dashboard from './application-components/dashboard/dashboard.component';
import ResourceManagement from './application-components/resource-management/resource-management.component';
import SearchRawDeatils from './application-components/search-rawdata/search-rawdata.component';
import NavBar from './application-components/layout/nav-bar/nav-bar.component';
import Logout from './application-components/account/Logout/logout.components';
import EditCustomerDeatils from './application-components/customer/edit-customer-details/edit-customer-details.component';
import Reprocess from './application-components/reprocess/reprocess.component';

const HomePage: React.FC<any> = () => {

    return (
        <div>
            <NavBar />
            <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/customer" element={<CustomerDetails />} />
                <Route path="/resourceManagement" element={<ResourceManagement />} />
                <Route path="/searchRawdata" element={<SearchRawDeatils />} />
                <Route path="/reprocess" element={<Reprocess />} />
                <Route path="/edit-customer-details" element={<EditCustomerDeatils />} />
                <Route path="/logout" element={<Logout />} />
            </Routes>
        </div>
    );
};
export default HomePage;