import { StatusCodes } from "http-status-codes";
import {  URL_GET_CUSTOMERS_DATA, URL_GET_CUSTOMER_ALL_SOURCES, URL_REPROCESS } from "../constants/url";
import { HttpMethodType } from "../enum/http-method-type.enum";
import ApiService from "./api.service";

export class ReprocessService {

    public getCustomerAllSource = async(
        customer_id:string
    ): Promise<any> => {
        console.log(customer_id);
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_GET_CUSTOMER_ALL_SOURCES.replace('{customer_id}', customer_id));
        console.log(response);
        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

    public getCustomerData = async (
        payload:any
        ): Promise<any> => {
        let result = {};
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Post;
        const response = await apiService.send(URL_GET_CUSTOMERS_DATA, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }else if(response && response.status === Number(StatusCodes.NO_CONTENT)){
            result = [];
        }
        return result;
    };

    public reprocess = async (
        payload:any
        ): Promise<any> => {
        let result = {};
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Post;
        const response = await apiService.send(URL_REPROCESS, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }else if(response && response.status === Number(StatusCodes.NO_CONTENT)){
            result = [];
        }
        return result;
    };

}