import './tatvam-rating.component.scss';

import React, { useEffect, useState } from "react";
import { InputNumber } from 'antd';
import { IMAGE_CDN_ENDPOINT } from '../../../constants/url';

type TatvamRatingProps = {
    value: any;
    isDisable?: boolean;
    text?: string;
    ratingType: number | undefined;
    onRatingChange?: (value: number) => void;
};

const TatvamRating: React.FC<TatvamRatingProps> = (props: TatvamRatingProps) => {
    const [updateRating, setUpdateRating] = useState(props.ratingType);
    const [errorType, setErrorType] = useState("" as string);

    useEffect(() => {
        setUpdateRating(props.ratingType);
        if (props.ratingType) {
            setErrorType("");
        }
    }, [props.ratingType]);

    const handelRatingChange = (value: any) => {
        if (!(value >= 0 && value <= 5)) {
            setErrorType("error");
        } else {
            setErrorType("");
        }
        setUpdateRating(value);
        props.value.tatvam_rating = value;
        if (props.onRatingChange) {
            props.onRatingChange(value);
        }
    };

    const handleInputClick = (e:any) => {
        if(e.target.value){
            setUpdateRating(undefined);
            setErrorType("error");
        }
    }

    const handleClick = (value: number) => {
        if (value !== updateRating) {
            setUpdateRating(value);
            setErrorType("");
            props.value.tatvam_rating = value;
            if (props.onRatingChange) {
                props.onRatingChange(value);
            }
        }
    };

    const renderEmotion = () => {
        const emotions = [];
        let classes: string = 'emotion';
        for (let index = 1; index <= 5; index++) {
            if (Math.round(updateRating) === index) {
                classes = classes + ' active';
            } else {
                classes = 'emotion';
            }

            emotions.push(
                <img aria-hidden="true" src={`${IMAGE_CDN_ENDPOINT}/icons/smile_0${index}.jpg`} alt="emotion" className={classes} key={index} onClick={() => props?.isDisable ? null : handleClick(index)} />,
            );
        }
        return emotions;
    };

    return (
        <div className='tatvam-emoticon col-lg-2 col-md-3 col-sm-12 col-xs-12 p-2'>
            <p className='rating-label mb-0'>{props?.text}</p>
            {renderEmotion()}
            <InputNumber
                status={errorType}
                onKeyPress={(event) => {
                    if (!`${event.target.value}${event.key}`.match(/^[0-5]{1}$/)) {
                        event.preventDefault();
                    }
                }}
                disabled={props?.isDisable}
                value={updateRating}
                size={'small'}
                controls={false}
                onChange={handelRatingChange}
                onClick={handleInputClick}
            />
        </div>
    )
};

export default TatvamRating;