import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { logout } from "../../../../store/actions/account.actions";

const Logout:React.FC<any>=(props:any)=>{
    const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

    useEffect(()=>{
        localStorage.clear();
        dispatch(logout(false))
        navigate("/");
    });

    return <></>
};

export default Logout;