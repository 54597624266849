import './customer-classifier.component.scss'
import { InputNumber, Table } from "antd";
import React, { useState } from "react";
import { sorterFunction, sortingDirections } from "../../../../shared/components/antd-table/sorter.function";
import antdFilter from "../../../../shared/components/antd-table/antd-filter.function";
import { AntGridFilterType } from "../../../../enum/antd-grid-filter-type.enum";

type classifierDataProps = {
    data: any;
    handelAnnotationsData: () => void;
}

const Classifier: React.FC<classifierDataProps> = (props: classifierDataProps) => {

    const [onClickDelete, setOnClickDelete] = useState(false);
    const [sortedInfo, setSortedInfo] = useState('' as any);
    const [filteredInfo, setFilteredInfo] = useState(null as any);

    const handelDelete = (record: any) => {
        if (record.process_status === "Deleted") {
            props?.data.forEach((annotation_qus: any) => {
                if (annotation_qus._id === record._id) {
                    annotation_qus["previous_process_status"] = annotation_qus.process_status;
                    annotation_qus.process_status = "Yet to Process";
                }
            })
        } else {
            props?.data.forEach((annotation_qus: any) => {
                if (annotation_qus._id === record._id) {
                    annotation_qus["previous_process_status"] = annotation_qus.process_status;
                    annotation_qus.process_status = "Deleted";
                }
            })
        }
        setOnClickDelete(!onClickDelete);
    }

    const handelUpdateTatavamChange = (record: any, value: any) => {
        props?.data.forEach((annotation_qus: any) => {
            if (annotation_qus._id === record._id) {
                annotation_qus.tatvam_rating = value;
            }
            if (annotation_qus.tatvam_rating === null && annotation_qus.errorStatus === "error") {
                annotation_qus["errorStatus"] = "error";
            }
            else if (!((value >= 1 && value <= 5)) && (annotation_qus._id === record._id)) {
                annotation_qus["errorStatus"] = "error";

            } else {
                annotation_qus["errorStatus"] = "";
            }
        });
        props?.handelAnnotationsData();
        setOnClickDelete(!onClickDelete);
    };

    const handleClick = (record: any, value: any) => {
        props?.data.forEach((annotation_qus: any) => {
            if (annotation_qus._id === record._id) {
                annotation_qus.tatvam_rating = null;
            }
            if (!((value >= 1 && value <= 5)) && (annotation_qus._id === record._id)) {
                annotation_qus["errorStatus"] = "error";
            }
        });
        props?.handelAnnotationsData();
    };

    const columnDef = [
        {
            title: "Parent Type",
            dataIndex: "parent_type",
            width: "10%",
            className: 'text-start',
            filteredValue: filteredInfo?.parent_type || null,
            sortOrder: sortedInfo?.field === 'parent_type' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'parent_type', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("parent_type", AntGridFilterType.SearchBox, props?.data),
        },
        {
            title: "Parent Dimension",
            dataIndex: "parent_name",
            width: "15%",
            className: 'text-start',
            filteredValue: filteredInfo?.parent_name || null,
            sortOrder: sortedInfo?.field === 'parent_name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'parent_name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("parent_name", AntGridFilterType.SearchBox, props?.data)
        },
        {
            title: "Child Dimension",
            dataIndex: "keyword_name",
            width: "10%",
            className: 'text-start',
            filteredValue: filteredInfo?.keyword_name || null,
            sortOrder: sortedInfo?.field === 'keyword_name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'keyword_name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("keyword_name", AntGridFilterType.SearchBox, props?.data)
        },
        {
            title: "Original Tatvam Rating",
            dataIndex: "previous_tatvam_rating",
            width: "15%",
            className: 'text-center',
            filteredValue: filteredInfo?.previous_tatvam_rating || null,
            sortOrder: sortedInfo?.field === 'previous_tatvam_rating' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'previous_tatvam_rating', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("previous_tatvam_rating", AntGridFilterType.SearchBox, props?.data)
        },
        {
            title: "Updated Tatvam Rating",
            dataIndex: "tatvam_rating",
            width: "15%",
            className: 'text-center',
            filteredValue: filteredInfo?.tatvam_rating || null,
            sortOrder: sortedInfo?.field === 'tatvam_rating' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'tatvam_rating', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("tatvam_rating", AntGridFilterType.SearchBox, props?.data),
            render: (data: any, record: any) => (
                <InputNumber
                    className="text-center"
                    status={record.errorStatus}
                    onKeyPress={(event) => {
                        if (!`${event.target.value}${event.key}`.match(/^[1-5]{1}$/)) {
                            event.preventDefault();
                        }
                    }}
                    disabled={false}
                    value={record?.tatvam_rating}
                    size={'small'}
                    controls={false}
                    onChange={(value) => handelUpdateTatavamChange(record, value)}
                    onClick={(value) => handleClick(record, value)}
                />
            ),
        },
        {
            title: "Status",
            dataIndex: "process_status",
            width: "6%",
            className: 'text-start',
            filteredValue: filteredInfo?.process_status || null,
            sortOrder: sortedInfo?.field === 'process_status' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'process_status', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("process_status", AntGridFilterType.SearchBox, props?.data)
        },
        {
            title: "Action",
            width: "6%",
            className: 'text-center',
            render: (record: any) => (
                <a
                    aria-hidden='true'
                    href="#"
                    className="customer-classifier delete-link"
                    onClick={(e) => {
                        e.preventDefault();
                        handelDelete(record);
                    }}
                    tabIndex={0}
                >
                    {record?.process_status === "Deleted" ? "Un-Delete" : "Delete"}
                </a>
            ),
        },
    ];

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any,
    ) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    return (
        <Table
            columns={columnDef}
            dataSource={props?.data}
            bordered={true}
            pagination={false}
            onChange={handleTableChange}
        />
    )
};

export default Classifier;