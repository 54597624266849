import './login.component.scss';

import React, { Dispatch } from "react";
import { Button, Form, Input, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { CUSTOMER } from '../../../constants/routes';
import { AccountServices } from '../../../services/account.service';
import { AUTH_INVALID_CREDENTIALS } from '../../../constants/messages';
import { login } from '../../../store/actions/account.actions';
import { useDispatch } from 'react-redux';

const Login: React.FC<any> = () => {
    const navigate = useNavigate();
    const acccountServices = new AccountServices();
    const dispatch: Dispatch<any> = useDispatch();


    const onFormSubmit = (values: any) => {
        getCustomersDataStatus(values)
    };

    const getCustomersDataStatus = (userDetails: any) => {
        acccountServices.userLogin(userDetails)
            .then((response: any) => {
                if (response[0]?.is_authenticated) {
                    dispatch(login(response[0]));
                    navigate(CUSTOMER);
                } else if (!response[0].is_authenticated) {
                    notification['error']({
                        message: 'Login',
                        description: AUTH_INVALID_CREDENTIALS,
                    });
                }
            })
            .catch((responseError: any) => {
                notification['error']({
                    message: 'Login',
                    description: AUTH_INVALID_CREDENTIALS,
                });
            })
    };

    const onFinishFailed = (errorInfo: any) => {
    };

    const renderLoginForm = () => {
        return (
            <div className='form-container'>
                <Form
                    name="login"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFormSubmit}
                    onFinishFailed={onFinishFailed}
                    wrapperCol={{ span: 25 }}
                >
                    <p className='text-center mt-5 md-4'>OPMS-TATVAM </p>
                    <Form.Item
                        className='ms-2'
                        name="user_name"
                        rules={[{ required: true, message: 'Please input your username' }]}
                    >
                        <Input
                            size="large"
                            placeholder={'Username'}
                        />
                    </Form.Item>

                    <Form.Item
                        className='ms-2'
                        name="password"
                        rules={
                            [
                                { required: true, message: 'Please input your password!' }
                            ]
                        }
                    >
                        <Input.Password
                            size="large"
                            placeholder={'Password'}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button className="ms-2" value="large" type="primary" htmlType="submit" block>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    };

    return (
        <div className='login row'>
            {renderLoginForm()}
        </div>
    )
};

export default Login