export const IMAGE_CDN_ENDPOINT = 'https://cdn.tatvamanalytics.com/images/inapp-client';
export const URL_GET_ALL_CUSTOMERS = `/customers`;
export const URL_OVERALL_ACCURACY = `/api/customer/{customer_id}/overall_accuracy`;
export const URL_ACCURACY_TREND = `/api/customer/{customer_id}/trend_of_accuracy`;
export const URL_RESOURCE_TREND = `/api/resource_contribution_trend`;
export const URL_GET_DISTINC_STATUS=`/api/distinct/process_status`;
export const URL_CUSTOMER_SOURCE_STATUS=`/api/customers/source_wise/record_count?status={status}`;
export const URL_CUSTOMER_SERVEY= `/api/customer/survey/one_response`;
export const URL_PREVIOUS_SERVEY_QUESTION = `/api/customer/survey/get_previous_response`;
export const URL_CUSTOMER_LOGIN=`/api/login`;
export const URL_CUSTOMER_GOTO_RAWID = `/api/customer/survey/response/{rawId}`;
export const URL_GET_ALL_USERS = `/api/users/all`;
export const URL_UPDATE_TO_ALLOCATE =  `/api/customer/survey_responses/allocate_user`;
export const URL_RESOURCE_MANAGEMENT = `/api/resourceMgnt/getResourceWiseCount`;
export const URL_GET_CUSTOMER_ALL_SOURCES = `/api/customer/{customer_id}/allSources`;
export const URL_GET_CUSTOMERS_DATA =  `/api/reprocess/customer/details`;
export const URL_REPROCESS = `/api/reprocess`;