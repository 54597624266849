import 'antd/dist/reset.css';

import { Button, Input, Space } from 'antd';

import { FilterFilled } from '@ant-design/icons';
 import Highlighter from 'react-highlight-words';
import React from 'react';
import ObjectUtility from '../../../utility/object.utility';

let searchText = '';
let searchedColumn = '';
let searchInput: any;

const getColumnSearchProps = (dataIndex: any, placeholder?: string) => ({
	filterDropdown: ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	}: any) => (
		<div style={{ padding: 8 }}>
			<Input
				ref={node => {
					searchInput = node;
				}}
				//placeholder={`search ${dataIndex}`.replace('_', ' ')}
				placeholder={placeholder ? `Search ${placeholder}` : "Search"}
				value={selectedKeys[0]}
				onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
				style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Space>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					size="small"
					style={{ width: 90 }}
				>
					Ok
				</Button>
				<Button
					onClick={() => handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}
				>
					Reset
				</Button>
			</Space>
		</div>
	),
	filterIcon: (filtered: any) => (
		<FilterFilled style={{ color: filtered ? '#00000073' : undefined }} />
	),
	onFilter: (value: any, record: any) => {
		const cellValue = ObjectUtility.getObjectPropValue(record, dataIndex);
		return cellValue
			? cellValue
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase())
			: '';
	},
	onFilterDropdownOpenChange: (visible: any) => {
		if (visible) {
			setTimeout(() => searchInput.select(), 100);
		}
	},
	render: (text: any) =>
		searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
});

const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
	confirm();
	searchText = selectedKeys[0];
	searchedColumn = dataIndex;
};

const handleReset = (clearFilters: any) => {
	clearFilters();
	searchText = '';
};

export default getColumnSearchProps;
