export const DATA_FETCHING_ERROR = 'Application Facing Problem While Fetching Data';
export const CUSTOMER_LIST = 'Customers';
export const OVERALL_ACCURACY = 'Overall Accuracy';
export const RESOURCE_CONTIBUTION_TREND = 'Resource Contribution Trend';
export const TREND_OF_ACCURACY = 'Trend Of Accuracy';
export const DATA_LOST_INFORMATION = 'Modified records will be lost.';
export const NO_PREVIOUS_DATA = 'There is no Previous record found.';
export const AUTH_INVALID_CREDENTIALS='Incorrect username or password!';
export const DATA_SUCCESS = `Successfully updated data.`;
export const NO_RECORDS_FOUND = `No records found!`;
export const LOGOUT_CONFIRMATION = `Are you sure want to logout?`;
export const PREVIOUS_RECORD_FETCH =`Previous record found`;
export const NO_DATA_FOUND =`No Data found`;
export const CUSTOMER_WITH_RAWID =  `Record found with the RawID`;
export const NO_USER_WITH_RAWID = `RawID does not exist`;
export const CUSTOMER_RAWID_EMPTY =`Please enter Raw ID.`;
export const TATVAM_RATING_EMPTY = `Please enter Updated Tatvam Rating.`;
export const ROWS_TO_ALLOCATE = `Please select the rows to alloacate`;
export const RESOURCE_MANAGEMENT = `Resource Management`;
export const SEARCH_RAWID = `RawId`;
export const REPROCESS_CUSTOMER = `Reprocess`;
export const NO_USER_WITH_CUST_ID =  `CustomerID does not exist`;
export const ALL_USERS_COMPLETED =`All records completed`;