export const SET_AUTO_REFRESH_ACTIVE: string = 'SET_AUTO_REFRESH_ACTIVE';
export const SET_AUTO_REFRESH_INACTIVE: string = 'SET_AUTO_REFRESH_INACTIVE';

export function setAutoRefreshActive(): IAutoRefreshActionType {
	return { type: SET_AUTO_REFRESH_ACTIVE };
}

export function setAutoRefreshInActive(): IAutoRefreshActionType {
	return { type: SET_AUTO_REFRESH_INACTIVE };
}

interface IAutoRefreshActionType {
	type: string;
}
