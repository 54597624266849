import { SET_AUTO_REFRESH_ACTIVE, SET_AUTO_REFRESH_INACTIVE } from '../actions/auto-refresh.ction';

import { IActionBase } from '../models/root.interface';
import { IAutoRefreshState } from '../models';

const initialState: IAutoRefreshState = {
	isActive: true,
};

const autoRefreshReducer = (
	state: IAutoRefreshState = initialState,
	action: IActionBase,
): IAutoRefreshState => {
	switch (action.type) {
		case SET_AUTO_REFRESH_ACTIVE: {
			return { ...state, isActive: true };
		}
		case SET_AUTO_REFRESH_INACTIVE: {
			return { ...state, isActive: false };
		}
		default:
			return state;
	}
};

export default autoRefreshReducer;
