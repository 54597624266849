import React, { useState, useEffect } from "react";
import { SizeMe } from 'react-sizeme';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly, { Layout, PlotData } from 'plotly.js-basic-dist';
import WidgetCard from "../../widgetCard/widget-card.component";
import { TrendAccuracyTypes } from "../../../../types/trendAccuracy.types";
import ChartHelper from "../../../../reports/chart-helper";

const Plot = createPlotlyComponent(Plotly);

type TrendAccuracyProps = {
    datas: TrendAccuracyTypes[];
    title: string;
}

const TrendOfAccuracy: React.FC<TrendAccuracyProps> = (props: TrendAccuracyProps) => {
    const { datas, title } = props;
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const xAxisData: any[] = [];
    const yAxisRanges: any[] = [];

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener('resize', resizeWindow);
        return () => window.removeEventListener('resize', resizeWindow);
    });

    const getChartData = (): Partial<PlotData>[] => {
        const trendAccuracy: any[] = [];
        if (datas) {
            datas.forEach((trend) => {
                xAxisData.push(trend.week_year);
                trendAccuracy.push(trend.accuracy);
                yAxisRanges.push(trend.accuracy);
            })
        }
        return [
            ChartHelper.PrepareGroupBarDataLayout(
                xAxisData,
                trendAccuracy,
                'Trend Accuracy',
                '#1f77b4',
                'Trend Accuracy<br>(%{x}, %{y:,})<extra></extra>',
            )
        ]
    }

    const getChartLayout = (): Partial<Layout> => {
        const layout = ChartHelper.PrepareBarLayout(
            `<b>${'Trend Of Accuracy'}</b>`,
            `<b>${'Accuracy Percentage'}</b>`,
        );
        return {
            ...layout,
            margin: { ...layout.margin, r: 110, t: 20 },
            legend: {
                ...layout.legend,
                x: 1.1,
                y: 1,
                orientation: 'v',
            },
        };
    }

    const renderChart = () => {
        if (!datas.length) {
            return (
                <h3 className="widget-card-no-data">No Data</h3>
            );
        }
        return (
            <SizeMe>{() =>
                <Plot
                    data={getChartData()}
                    layout={getChartLayout()}
                    config={{ displayModeBar: false }}
                    onLegendDoubleClick={() => false}
                    onLegendClick={() => false}
                    className="plotly-chart"
                />
            }
            </SizeMe>
        )
    }

    return (
        <WidgetCard
            title={title}
            childComp={renderChart()}
        />
    )
}
export default TrendOfAccuracy;