import { LOG_IN, LOG_OUT } from '../actions/account.actions';

import { IAccount } from '../models/account.interface';
import { IActionBase } from '../models/root.interface';

const initialState: IAccount = {
	user_name: '',
	first_name: '',
	middle_name: '',
	password: '',
	last_name: '',
	email_id: '',
	auth_type: '',
	status: '',
	created_by: '',
	created_time: '',
	updated_by: '',
	updated_time: '',
	update_counter: 0,
	is_authenticated: false
};

function accountReducer(
	state: IAccount = initialState,
	action: IActionBase,
): IAccount {
	switch (action.type) {
		case LOG_IN: {
			return {
				...state,
				user_name: action.account.user_name,
				first_name: action.account.first_name,
				middle_name: action.account.middle_name,
				password: action.account.password,
				last_name: action.account.last_name,
				email_id: action.account.email_id,
				auth_type: action.account.auth_type,
				status: action.account.status,
				created_by: action.account.created_by,
				created_time: action.account.created_time,
				updated_by: action.account.updated_by,
				updated_time: action.account.updated_time,
				update_counter: action.account.update_counter,
				is_authenticated: action.account.is_authenticated
			};
		}
		case LOG_OUT: {
			sessionStorage.removeItem('persist:root');
			localStorage.removeItem('persist:root');
			return {
				user_name: '',
				first_name: '',
				middle_name: '',
				password: '',
				last_name: '',
				email_id: '',
				auth_type: '',
				status: '',
				created_by: '',
				created_time: '',
				updated_by: '',
				updated_time: '',
				update_counter: 0,
				is_authenticated: false
			};
		}
		default:
			return state;
	}
}

export default accountReducer;
