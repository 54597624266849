import './customer.component.scss';

import { Select, Table, notification, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AntGridFilterType } from "../../../enum/antd-grid-filter-type.enum";
import antdFilter from "../../../shared/components/antd-table/antd-filter.function";
import { sorterFunction, sortingDirections } from "../../../shared/components/antd-table/sorter.function";
import { useNavigate } from 'react-router-dom';
import { EDIT_CUSTOMER_DETAILS } from '../../../constants/routes';
import { CustomerService } from '../../../services/customer.service';
import { CUSTOMER_LIST, DATA_FETCHING_ERROR, ROWS_TO_ALLOCATE } from '../../../constants/messages';
import moment from 'moment';
import { DateFormat } from '../../../enum/date-format.enum';
import { IStateType } from '../../../store/models/root.interface';
import { IAccount } from '../../../store/models';
import { useSelector } from 'react-redux';

const CustomerDetails: React.FC<any> = () => {
    const navigate = useNavigate();
    const defaultStatus = "Yet To Process";
    const [gridData, setGridData] = useState([] as any);
    const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
    const [statusData, setStatusData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isAllocateOpen, setIsAllocateOpen] = useState(false as boolean);
    const [isRowsQuestion, setIsRowsQuestion] = useState([] as any);
    const [isDataLoaded, setIsDataLoaded] = useState(false as boolean);
    const [allUsers, setAllUsers] = useState([{}]);
    const [selectedUser, setSelectedUser] = useState("" as string);
    const customerService = new CustomerService();
    const [sortedInfo, setSortedInfo] = useState('' as any);
    const [filteredInfo, setFilteredInfo] = useState(null as any);
    const account: IAccount = useSelector((state: IStateType) => state?.account);

    useEffect(() => {
        getDistinctStatus();
    }, [])

    useEffect(() => {
        if (selectedStatus?.length) {
            getCustomersDataStatus();
            getUsersToAllocate();
        }
    }, [selectedStatus, isDataLoaded]);

    const getDistinctStatus = () => {
        customerService.getDistinctStatus()
            .then((response: any) => {
                setStatusData(response);
            })
            .catch((responseError) => {
                setSelectedStatus("");
                notification['error']({
                    message: CUSTOMER_LIST,
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const getCustomersDataStatus = () => {
        customerService.getCustomerStatusData(selectedStatus)
            .then((response: any) => {
                setGridData(response);
            })
            .catch((responseError) => {
                notification['error']({
                    message: 'Customer Status Data',
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const getUsersToAllocate = () => {
        customerService.getAllUsers()
            .then((response: any) => {
                const unAllocate = {
                    "user_name": "un-allocate",
                    "first_name": "",
                    "last_name": ""
                }
                response.unshift(unAllocate);
                setAllUsers(response);
            })
            .catch((responseError) => {
                notification['error']({
                    message: CUSTOMER_LIST,
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const updateToAllocate = () => {
        let allocatePayloadData: any = [];
        isRowsQuestion.map((question: any) => {
            allocatePayloadData.push(
                {
                    customer_id: question.customer_id,
                    source_name: question.source_name,
                    process_status: question.process_status,
                    process_date: question.process_date,
                    allocated_to: question.allocated_to
                }
            )
        });
        const allocatePayload = {
            allocated_to: (selectedUser !== "un-allocate") ? selectedUser : "",
            allocated_time: new Date(),
            updated_by: account?.user_name,
            data: allocatePayloadData
        };
        customerService.updateAllocate(allocatePayload)
            .then((response: any) => {
                setIsDataLoaded(!isDataLoaded);
                setIsRowsQuestion([]);
                setSelectedRowKeys([]);
            })
            .catch((responseError) => {
                setIsDataLoaded(!isDataLoaded);
                notification['error']({
                    message: CUSTOMER_LIST,
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const columnDef: any[] = [
        {
            title: "Customer Id",
            dataIndex: "customer_id",
            key: "customer_id",
            width: "140px",
            className: 'text-center',
            filteredValue: filteredInfo?.customer_id || null,
            sortOrder: sortedInfo?.field === 'customer_id' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'customer_id', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("customer_id", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Customer Name",
            dataIndex: "name",
            key: "name",
            width: "250px",
            className: 'text-start',
            filteredValue: filteredInfo?.name || null,
            sortOrder: sortedInfo?.field === 'name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("name", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Customer Category",
            dataIndex: "category",
            key: "category",
            width: "180px",
            className: 'text-start',
            filteredValue: filteredInfo?.category || null,
            sortOrder: sortedInfo?.field === 'category' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'category', 'category'),
            sortDirections: sortingDirections,
            ...antdFilter("category", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Process Date",
            dataIndex: "process_date",
            key: "process_date",
            width: "150px",
            className: 'text-center',
            filteredValue: filteredInfo?.process_date || null,
            sortOrder: sortedInfo?.field === 'process_date' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'process_date', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("process_date", AntGridFilterType.SearchBox, gridData),
            render: (record: any) => (
                <span>{moment(record).format(DateFormat.DD_MMM_YY)}</span>
            )
        },
        {
            title: "Source",
            dataIndex: "source_name",
            key: "source_name",
            width: "220px",
            className: 'text-start',
            filteredValue: filteredInfo?.source_name || null,
            sortOrder: sortedInfo?.field === 'source_name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'source_name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("source_name", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "# of Records",
            dataIndex: "no_of_records",
            key: "no_of_records",
            width: "150px",
            className: 'text-center',
            filteredValue: filteredInfo?.no_of_records || null,
            sortOrder: sortedInfo?.field === 'no_of_records' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: number, b: number) => sorterFunction(a, b, 'no_of_records', 'number'),
            sortDirections: sortingDirections,
            ...antdFilter("no_of_records", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Allocated To",
            dataIndex: "allocated_to",
            key: "allocated_to",
            width: "180px",
            className: 'text-start',
            filteredValue: filteredInfo?.allocated_to || null,
            sortOrder: sortedInfo?.field === 'allocated_to' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'allocated_to', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("allocated_to", AntGridFilterType.SearchBox, gridData),
        },
    ];

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any,
    ) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
        setIsRowsQuestion(selectedRows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handelAllocateClick = () => {
        if (isRowsQuestion.length > 0) {
            setIsAllocateOpen(true);
        } else {
            Modal.warning({
                title: "Alert",
                content: ROWS_TO_ALLOCATE,
                onOk: () => { }
            });
        }
    }

    const handelAllocateAdd = () => {
        if (selectedUser.length > 0 && selectedUser !== undefined && selectedUser !== null) {
            updateToAllocate();
        };
        setIsAllocateOpen(false);
    };

    const handelAllocateClose = () => {
        setIsAllocateOpen(false);
    };

    const handelChangeUsers = (value: any) => {
        setSelectedUser(value);
    };

    const handelSelectAllocate = () => {
        return (
            <Select
                style={{ width: '100%' }}
                placeholder={"Select User"}
                showSearch
                filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handelChangeUsers}
            >
                {allUsers?.map((user: any, index) => {
                    return (
                        <Select.Option value={user.user_name} key={user.user_name} >
                            {user.user_name}
                        </Select.Option>
                    );
                })}
            </Select>
        )
    };

    const renderTable = () => {
        return (
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 border border-dark ">
                <div className="row p-2">
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                        <span className={` col-lg-6 col-md-3 col-sm-6 col-xs-12 p-0 ms-2 mb-2`}>
                            Select a records to start validation process
                        </span>
                    </div>
                    {selectedStatus.toLocaleLowerCase() === "yet to process" &&
                        <div
                            aria-hidden='true'
                            className='col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2 float-end'
                            role="button"
                            tabIndex={0}
                            onClick={handelAllocateClick}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handelAllocateClick();
                                }
                            }}
                        >
                            <span className="allocate">Allocate</span>
                        </div>
                    }
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Table
                            rowSelection={rowSelection}
                            columns={columnDef}
                            dataSource={gridData}
                            rowKey={(data: any, index: any) => {
                                return index;
                            }}
                            onChange={handleTableChange}
                            bordered={true}
                            pagination={false}
                            onRow={(record: any, rowIndex) => {
                                return {
                                    onClick: event => navigate(EDIT_CUSTOMER_DETAILS, {
                                        state: {
                                            rowData: record,
                                            status: selectedStatus,
                                        },
                                    })
                                };
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const handelCustomerStatus = (value: any) => {
        setIsRowsQuestion([]);
        setSelectedRowKeys([]);
        setSelectedStatus(value);
    };

    const renderModel = () => {
        return (
            <Modal
                width={'25%'}
                title="Select User"
                open={isAllocateOpen}
                onOk={() => { handelAllocateAdd() }}
                onCancel={handelAllocateClose}
                okText="Add"
                cancelText="Close"
                closable={false}
                keyboard={false}
                maskClosable={false}
            >
                {(handelSelectAllocate())}
            </Modal >
        )
    }

    const renderCustomerStatusSelect = () => {
        return (
            <div className="row p-2">
                <div className='col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                    <label htmlFor="statusControl" className={` col-lg-2 col-md-3 col-sm-6 col-xs-12 p-0`}>
                        Status :
                    </label>

                    <Select
                        id="statusControl"
                        style={{ width: 300 }}
                        placeholder="Select Status"
                        className="col-lg-6 col-md-4 col-sm-6 col-xs-12 p-0 mt-3"
                        onChange={handelCustomerStatus}
                        defaultValue={selectedStatus}
                        showSearch
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {statusData?.map((status: any, index) => {
                            return (
                                <Select.Option value={status} key={status} >
                                    {status}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <div className='col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 current-date'>
                    <p className="m-0">Current Date:{moment(new Date()).format(DateFormat.DD_MMM_YY)}</p>
                </div>
            </div>
        )
    };

    return (
        <div className='customers scrollable-page-area p-2'>
            {renderCustomerStatusSelect()}
            {renderTable()}
            {isAllocateOpen && renderModel()}
        </div>
    );
};

export default CustomerDetails;
