import React, { useState, useEffect } from "react";
import Plotly, { Layout } from 'plotly.js-basic-dist';
import { SizeMe } from 'react-sizeme';

import createPlotlyComponent from 'react-plotly.js/factory';
import ChartHelper from "../../../../reports/chart-helper";
import WidgetCard from "../../widgetCard/widget-card.component";

const Plot = createPlotlyComponent(Plotly);

type overallAccuracyTypes = {
    datas: any;
    title: string;
};

const OverAllAccuracy: React.FC<overallAccuracyTypes> = (props: overallAccuracyTypes) => {
    const [, setWindowWidth] = useState(0);
    const [, setWindowHeight] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener('resize', resizeWindow);
        return () => window.removeEventListener('resize', resizeWindow);
    }, []);

    function gaugePointer(value: any) {
        let degrees = 100 - value,
            radius = .5;
        let radians = degrees * Math.PI / 100;
        let x = radius * Math.cos(radians);
        let y = radius * Math.sin(radians);
        // Path: may have to change to create a better triangle
        let mainPath = 'M -.0 -0.035 L .0 0.035 L ',
            pathX = String(x),
            space = ' ',
            pathY = String(y),
            pathEnd = ' Z';
        let path = mainPath.concat(pathX, space, pathY, pathEnd);
        return path;
    };

    const getChartData = (): any[] => {
        return [
            ChartHelper.PrepareScatterChartData(
                [0],
                [0],
                '',
                '850000',
                `Overall Accuracy<br>${props?.datas?.accuracy}<extra></extra>`
            ),
            ChartHelper.getGaugeChartDataObject(
                [10, 10, 10, 10, 10, 50],
                undefined,
                ['81 - 100', '61 - 80', '41-60', '21-40', '20-0', ''],
                undefined,
                undefined,
                undefined,
                ['81 - 100', '61 - 80', '41-60', '21-40', '20-0', ''],
            )
        ]
    };

    const getChartLayout = (): Partial<Layout> => {
        const layout = ChartHelper.getGaugeChartLayoutObject();
        const scatterLayout = ChartHelper.PrepareScatterChartLayout('', '', [], [], false);

        return {
            ...layout,
            ...scatterLayout,
            title: '',
            // hovermode: true,
            margin: { l: 10, r: 10, t: 100 },
            legend: {
                ...layout.legend,
                x: 1.1,
                y: 1,
                orientation: 'v',
            },
            shapes: [{
                type: 'path',
                path: gaugePointer(props?.datas?.accuracy),
                fillcolor: '850000',
                line: {
                    color: '850000'
                }
            }],
            xaxis: {
                zeroline: false,
                showticklabels: false,
                fixedrange: true,
                showgrid: false,
                range: [-1, 1]
            },
            yaxis: {
                zeroline: false,
                showticklabels:
                    false,
                fixedrange: true,
                showgrid: false,
                range: [-1, 1]
            }
        };

    };

    const renderChart = () => {
        if (Object.keys(props?.datas).length === 0) {
            return (
                <h3 className="widget-card-no-data">No Data</h3>
            );
        }
        return (
            <SizeMe>{() =>
                <Plot
                    data={getChartData()}
                    layout={getChartLayout()}
                    config={{ displayModeBar: false }}
                    onLegendDoubleClick={() => false}
                    onLegendClick={() => false}
                    className="plotly-chart"
                />
            }
            </SizeMe>
        )
    };

    return (
        <WidgetCard
            title={props?.title}
            childComp={renderChart()}
        />
    )
}
export default OverAllAccuracy;
