import { IActionBase, IRootStateType, IStateType } from '../models';
import { Reducer, combineReducers } from 'redux';

import { UPDATE_CURRENT_PATH } from '../actions/root.actions';
import accountReducer from './account.reducer';
import autoRefreshReducer from './auto-refresh.reducer';
import versionReducer from './version.reducer';

const initialState: IRootStateType = {
	page: { area: 'home', subArea: '' },
};

function rootReducer(
	state: IRootStateType = initialState,
	action: IActionBase,
): IRootStateType {
	switch (action.type) {
		case UPDATE_CURRENT_PATH:
			return { ...state, page: { area: action.area, subArea: action.subArea } };
		default:
			return state;
	}
}

const rootReducers: Reducer<IStateType> = combineReducers({
	account: accountReducer,
	autoRefresh: autoRefreshReducer,
	root: rootReducer,
	appVersion: versionReducer,
});

export default rootReducers;
