import ObjectUtility from "../../../utility/object.utility";


const sorterFunction = (
	data1: any,
	data2: any,
	dataIndex: string,
	sorterType: string,
) => {
	if (
		!data1 ||
		!data2 ||
		Object.keys(data1)?.length < 1 ||
		Object.keys(data1)?.length < 1
	) {
		return {};
	}

	const value1 = ObjectUtility.getObjectPropValue(data1, dataIndex);
	const value2 = ObjectUtility.getObjectPropValue(data2, dataIndex);
	if (value1 === undefined || value2 === undefined) {
		return {};
	}

	if (sorterType === 'number') {
		return value1 - value2;
	} else if (sorterType === 'string') {
		return value1.localeCompare(value2);
	} else if (sorterType === 'date') {
		return new Date(value1).getTime() - new Date(value2).getTime();
	}
};

const sortingDirections = ['ascend', 'descend'];

export { sorterFunction, sortingDirections };
