import thunkMiddleware from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import storageSession from 'redux-persist/lib/storage/session'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducers from './reducers/root.reducer';

const middleware = [thunkMiddleware];
const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'root',
	storage: storageSession,
	stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
	blacklist: ['isLoader'],
};

const persisted = persistReducer<any, any>(persistConfig, rootReducers);

export const store = createStore(
	persisted,
	composeEnhancers(applyMiddleware(...middleware)),
);

/**
 * Subscribing to the store makes it possible to
 * execute some code every time the state is updated.
 */
store.subscribe(() => {
});

export const persistor = persistStore(store);
