import _ from 'lodash';
import { Color, Font, Layout, Legend, LayoutAxis, PlotData } from 'plotly.js';

class ChartHelper {
    private static colors: string[] = ['#56cb5e', '#85d946', '#f5e63b', '#f1882f', '#ea4b2e', 'white' ];
	private static ResourceColors:string[]=['#ff7f0e','#1f77b4','#9467bd','#2ca02c','#d62728']
	
	public static getResourceColors(): string[] {
		return ChartHelper.ResourceColors;
	}

	public static PrepareStackDataLayout(
		xAxisDatas: any[],
		yAxisDatas: any[],
		name: string,
		markerColor: string | string[] | undefined,
		hovertemplate: string | undefined,
		orientation: any,
		textposition?: any,
		text?: string | string[],
		width?: number | number[],
		hoverinfo?: any | undefined,
		textfont?: string | string[] | undefined,
	): Partial<PlotData> {
		return {
			x: xAxisDatas,
			y: yAxisDatas,
			name: name,
			type: 'bar',
			marker: {
				color: markerColor,
			},
			textposition: textposition,
			hovertemplate: hovertemplate,
			hoverinfo: hoverinfo,
			orientation: orientation,
			text: text,
			width: width,
			textfont: {
				color: textfont,
			},
			cliponaxis: false,
		};
	}

	public static PrepareStackChartLayout(
		xAxisTitle: string,
		yAxisTitle: string,
		yAxisTickValues: any[] = [],
		showLegend: boolean = false,
		xAsixTickAngle: number = -30,
		xAxisTickValues?: any,
		showline?: boolean,
		zeroline?: boolean,
		yAxisTickFormat?: string,
		xAxisStandoff?: number,
		yAxisStandoff?: number,
		tickMode?: any,
		xAxisType?: any,
		yAxisType?: any,
		maxYValue?: any,
	): Partial<Layout> {
		return {
			title: {
				font: this.axisTitleFont(),
			},
			barmode: 'stack',
			font: this.chartFont(),
			showlegend: showLegend,
			legend: this.chartLegend(),
			xaxis: this.prepareXAxis(xAxisTitle, xAxisTickValues, showline, zeroline, xAsixTickAngle, xAxisStandoff, tickMode, xAxisType),
			yaxis: this.prepareYAxis(yAxisTitle, yAxisTickValues, yAxisTickFormat, showline, zeroline, yAxisStandoff, tickMode, yAxisType, maxYValue),
			autosize: true,
			margin: this.chartMargin(),
			height: 360,
			hovermode: 'closest',
			dragmode: 'pan',
		};
	}

	//refactor this function
	public static PrepareBarLayout(
		xAxisTitle: string,
		yAxisTitle: string,
		xAxisTickValues: any[] = [],
		yAxisTickValues: any[] = [],
		showLegend: boolean = false,
		showline?: boolean,
		zeroline?: boolean,
		xAxisTickAngle: number = -30,
		yAxisTickFormat?: string,
		xAxisStandoff?: number,
		yAxisStandoff?: number,
		tickMode?: any,
		xAxisType?: any,
		yAxisType?: any,
		maxYValue?: any,
	):Partial<Layout>{
		return{
			barmode: 'group',
			font: this.chartFont(),
			showlegend: showLegend,
			legend: this.chartLegend(),
			xaxis: this.prepareXAxis(xAxisTitle, xAxisTickValues, showline, zeroline, xAxisTickAngle, xAxisStandoff, tickMode, xAxisType),
			yaxis: this.prepareYAxis(yAxisTitle, yAxisTickValues, yAxisTickFormat, showline, zeroline, yAxisStandoff, tickMode, yAxisType, maxYValue),
			autosize: true,
			margin: this.chartMargin(),
			height: 360,
			hovermode: 'closest',
			dragmode: 'pan',
		}
	}

	public static PrepareGroupBarDataLayout(
		xAxisDatas: any[],
		yAxisDatas: any[],
		name: string,
		markerColor: string | undefined,
		hovertemplate: string,
		text?: string | string[],
	): Partial<PlotData> {
		return {
			x: xAxisDatas,
			y: yAxisDatas,
			name: name,
			type: 'bar',
			marker: {
				color: markerColor,
			},
			hovertemplate: hovertemplate,
			text: _.isArray(text) ? text.map(String) : text,
			textposition: "auto",
		};
	}

    public static getGaugeChartLayoutObject(): Partial<Layout> {
		return {
			autosize: true,
			font: this.chartFont(),
			height: 350,
			legend: this.chartLegend(),
			margin: this.chartMargin(),
			titlefont: this.titleFont(),
		};
	}

    public static getGaugeChartDataObject(
		values:any[],
		rotation?:number | undefined,
		text?:any[],
		textinfo?:string,
		textposition?:string,
        markerColor?: Color[] | undefined,
		labels?:any[],
		hole?:number,
    ):Partial<PlotData>{
        return {
            values: values,
            rotation: rotation || 90,
			text:text,
            textinfo: 'text',
            textposition:'outside',	
            marker:{
				colors: markerColor || ChartHelper.colors,
            },
			labels: labels,
            hole: hole || .5,
			hoverinfo: 'name',
			type:'pie',
            showlegend: false
        }

    }

	public static PrepareScatterChartData(
		xAxisDatas: any[],
		yAxisDatas: any[],
		name: string,
		markerColor: string | undefined,
		hovertemplate: string,
		mode?: any,
		fill?: any,
		shape?: any,
		stackgroup?: string,
		marker?:any,
	): Partial<PlotData> {
		return {
			x: xAxisDatas,
			y: yAxisDatas,
			name: name,
			type: 'scatter',
			hovertemplate: hovertemplate,
			mode: xAxisDatas.length > 1 ? (mode || 'lines+markers') : 'markers',
			fill: fill ? fill : 'none',
			line: {
				shape: shape ? shape : 'spline',
				color: markerColor,
			},
			fillcolor: markerColor,
			stackgroup: xAxisDatas.length > 1 ? stackgroup : '',
			marker:{
				size: 18, 
				color:'850000'
			}
		};
	}

	public static PrepareScatterChartLayout(
		xAxisTitle: string,
		yAxisTitle: string,
		xAxisTickValues: any[] = [],
		yAxisTickValues: any[] = [],
		showLegend: boolean = false,
		showline?: boolean,
		zeroline?: boolean,
		xAxisTickAngle: number = -30,
		yAxisTickFormat?: string,
		xAxisStandoff?: number,
		yAxisStandoff?: number,
		tickMode?: any,
		xAxisType?: any,
		yAxisType?: any,
		maxYValue?: any
	): Partial<Layout> {
		return {
			font: this.chartFont(),
			showlegend: showLegend,
			legend: this.chartLegend(),
			xaxis: this.prepareXAxis(xAxisTitle, xAxisTickValues, showline, zeroline, xAxisTickAngle, xAxisStandoff, tickMode, xAxisType),
			yaxis: this.prepareYAxis(yAxisTitle, yAxisTickValues, yAxisTickFormat, showline, zeroline, yAxisStandoff, tickMode, yAxisType, maxYValue),
			autosize: true,
			margin: this.chartMargin(),
			height: 360,
			hovermode: 'closest',
			dragmode: 'pan',
		};
	}
	
	private static prepareXAxis(
		title: string,
		tickValues: any[] = [],
		showline?: boolean,
		zeroline?: boolean,
		ticketAngle: number = -30,
		standoff?: number,
		tickMode?: any,
		xAxisType?: any,
	): Partial<LayoutAxis> {
		return {
			title: {
				text: title,
				standoff: standoff,
			},
			tickangle: ticketAngle,
			titlefont: this.axisTitleFont(),
			showline: showline,
			zeroline: zeroline,
			tickvals: tickValues?.length <= 0 ? undefined : tickValues,
			tickmode: (tickMode || 'linear'),
			type: (xAxisType || 'category'),
			range: tickValues?.length > 8 ? [-0.5, 7.5] : [],
			showgrid: false,
		};
	}

	private static prepareYAxis(
		title: string,
		tickValues: any[],
		tickFormat?: string,
		showline?: boolean,
		zeroline?: boolean,
		standoff?: number,
		tickMode?: any,
		yAxisType?: any,
		maxYValue?: any,
	): Partial<LayoutAxis> {
		return {
			title: {
				text: title,
				standoff: standoff,
			},
			showline: showline,
			zeroline: zeroline,
			fixedrange: true,
			titlefont: this.axisTitleFont(),
			showgrid: true,
			tickformat: tickFormat,
			tickmode: (tickMode || undefined),
			type: (yAxisType || undefined),
			dtick: maxYValue && this.getMaxYaxisValue(maxYValue)[0].dtick,
			range: maxYValue ? ([0, this.getMaxYaxisValue(maxYValue)[0].max]) :
				(tickValues?.length <= 0 ? undefined : tickValues),
		};
	}

    private static axisTitleFont() {
		return {
			family: 'Roboto,sans-serif',
			size: 13,
		};
	}

	private static chartFont() {
		return {
			family: 'Lato',
			size: 11,
			color: '#000',
		};
	}

	private static chartLegend(): Partial<Legend> {
		return {
			y: 1.2,
			x: 0,
			orientation: 'h',
		};
	}

	private static chartMargin() {
		return { l: 50, r: 10, pad: 0, t: 60, b: 60 };
	}

    public static readonly textWrapper = (labels: any[], textLimit: number) => {
		if (labels?.length) {
			let regExp = new RegExp(`.{1,${textLimit}}(\\s|$)`, "g");
			
			return labels.map((text: { match: (arg0: RegExp) => any[]; }) => text !== "" ? text?.match(regExp).join('<br>') : "");
		} else {
			return [];
		}
	};

	private static titleFont(): Partial<Font> {
		return {
			size: 11,
			color: '#444',
			family: '\'Lato\', sans-serif !important ',
		};
	}

	public static readonly getMaxYaxisValue = (value: number) => {
		let dtick;
		let yMax;
		let max = value / 1000;
		if (max > 100000) {
			yMax = null;
			dtick = null;
		} else if (max > 10000 && max <= 100000) {
			yMax = Math.ceil(value / 10000000) * 10000000;
			dtick = 10000000;
		} else if (max > 1000 && max <= 10000) {
			yMax = Math.ceil(value / 1000000) * 1000000;
			dtick = 1000000;
		} else if (max > 100 && max <= 1000) {
			yMax = Math.ceil(value / 100000) * 100000;
			dtick = 100000;
		} else if (max > 10 && max <= 100) {
			yMax = Math.ceil(value / 10000) * 10000;
			dtick = 10000;
			if (max > 10 && max <= 50) {
				yMax = Math.ceil(value / 5000) * 5000;
				dtick = 5000;
			}
			if (max > 10 && max <= 25) {
				yMax = Math.ceil(value / 2500) * 2500;
				dtick = 2500;
			}
			if (max > 10 && max <= 20) {
				yMax = Math.ceil(value / 2000) * 2000;
				dtick = 2000;
			}
			if (max > 10 && max <= 15) {
				yMax = Math.ceil(value / 1500) * 1500;
				dtick = 1500;
			}
		} else if (max >= 1 && max <= 10) {
			yMax = Math.ceil(value / 1000) * 1000;
			dtick = 1000;

			if (max >= 1 && max <= 5) {
				yMax = Math.ceil(value / 500) * 500;
				dtick = 500;
			}
			if (max >= 1 && max <= 2) {
				yMax = Math.ceil(value / 200) * 200;
				dtick = 200;
			}
		} else {
			max = value / 100;
			yMax = Math.ceil(value / 100) * 100;
			dtick = 100;
			if (max >= 1 && max <= 10) {
				max = value / 50;
				if (max >= 1 && max <= 10) {
					yMax = Math.ceil(value / 50) * 50;
					dtick = 50;
				}
				max = value / 25;
				if (max >= 1 && max <= 10) {
					yMax = Math.ceil(value / 25) * 25;
					dtick = 25;
				}
				max = value / 20;
				if (max >= 1 && max <= 10) {
					yMax = Math.ceil(value / 20) * 20;
					dtick = 20;
				}
				max = value / 15;
				if (max >= 1 && max <= 10) {
					yMax = Math.ceil(value / 15) * 15;
					dtick = 15;
				}
			} else {
				max = value / 10;
				yMax = Math.ceil(value / 10) * 10;
				dtick = 10;
				if (max >= 1 && max <= 10) {
					max = value / 5;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 5) * 5;
						dtick = 5;
					}
					max = value / 2;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 2) * 2;
						dtick = 2;
					}
					max = value / 1;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 1) * 1;
						dtick = 1;
					}
				} else if (max <= 10 && max <= 1) {
					max = value / 5;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 5) * 5;
						dtick = 5;
					}
					max = value / 2;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 2) * 2;
						dtick = 2;
					}
					max = value / 1;
					if (max >= 1 && max <= 10) {
						yMax = Math.ceil(value / 1) * 1;
						dtick = 1;
					}
				}
			}
		}
		return [{ max: yMax, dtick: dtick }];
	};
}

export default ChartHelper;