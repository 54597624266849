import './user-rating.component.scss';

import { InputNumber, Rate } from "antd";
import React from "react";

type UserRatingProps = {
    isDisabled?: boolean;
    value?: number;
    text?:string;
};

const UserRating: React.FC<UserRatingProps> = (props: UserRatingProps) => {

    const renderComponent = () => {
        return (
            <div className='user-rating col-lg-2 col-md-3 col-sm-12 col-xs-12 p-2'>
                <p className='rating-label mb-0'>{props?.text}</p>
                <Rate
                    allowHalf
                    disabled={props?.isDisabled}
                    defaultValue={props?.value}
                />
                <InputNumber
                    max={5}
                    disabled={props?.isDisabled}
                    defaultValue={props?.value}
                    size={'small'}
                    controls={false}
                />
            </div>
        )
    }

    return renderComponent();
};

export default UserRating;