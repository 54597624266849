import _ from 'lodash';
import getColumnSearchProps from './search-filter.function';
import { AntGridFilterType } from '../../../enum/antd-grid-filter-type.enum';

const antdFilter = (dataIndex: string, filterType: AntGridFilterType, datas?: any[], placeholder?: string
) => {
	if (!datas || datas?.length < 1) {
		return {};
	}
	if (filterType === AntGridFilterType.SearchBox) {
		return { ...getColumnSearchProps(dataIndex, placeholder) };
	} else if (filterType === AntGridFilterType.CheckBox) {
		let filters: any[] = [];
		const onFilter = (value: string, record: any) => record[dataIndex].indexOf(value) === 0;
		if (datas && datas.length > 0) {
			filters = _.map(_.groupBy(datas, dataIndex), (datas, dataIndex) => ({ text: dataIndex, value: dataIndex }));
		}

		return { filters: filters, onFilter: onFilter };
	}
};

export default antdFilter;