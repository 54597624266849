import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpMethodType } from '../enum/http-method-type.enum';
import { KeyValue } from '../types/key-value-types';

export class ApiService{
    private _headers: any = {};
	private _method: HttpMethodType = HttpMethodType.Post;
	private _responseType: string = '';

    constructor(){
        this._headers['Accept'] = 'application/json';
		this._headers['Content-Type'] = 'application/json';

    }

    get headers(): any {
		return this._headers;
	}

	set method(newMethod: HttpMethodType) {
		this._method = newMethod;
	}

	get responseType(): string {
		return this._responseType;
	}

	set responseType(value: string) {
		this._responseType = value;
	}

    public request<T>(body?: T): any {
		return {
			headers: this._headers,
			method: this._method.toString(),
			params: body,
		};
	}

	public resetHeaders(): void {
		this._headers = {};
	}

    public send(url: string, config?: any): Promise<AxiosResponse<any>> {
		if (!config?.headers) {
			config = { headers: this._headers };
		}

		if (this.responseType) {
			config['responseType'] = this.responseType;
		}

		switch (this._method) {
			case HttpMethodType.Get: {
				const getUrl = this.get(url, config);
				return getUrl;
			}
			case HttpMethodType.Post: {
				return this.post(url, config);
			}
			case HttpMethodType.Patch: {
				return this.patch(url, config);
			}
			case HttpMethodType.Delete: {
				return this.delete(url, config);
			}
			default: {
				throw new Error('Not Implemented');
			}
		}
	}

    public setHeaders(headers: KeyValue<string, string>[]): this {
		for (const i in headers) {
			this._headers[headers[i].key] = headers[i].value;
		}
		return this;
	}

    private populateRequestConfig(config?: any): AxiosRequestConfig | undefined {
		if (!config) {
			return undefined;
		}

		let requestConfig: AxiosRequestConfig = {};
		if (config?.headers) {
			requestConfig.headers = config.headers;
		}
		if (config?.baseURL) {
			requestConfig.baseURL = config.baseURL;
		}

		if (config?.responseType) {
			requestConfig.responseType = config.responseType;
		}
		return requestConfig;
	}

    private delete(url: string, config?: any): Promise<AxiosResponse<any>> {
		return axios.delete(url, this.populateRequestConfig(config));
	}

	private get(url: string, config?: any): Promise<AxiosResponse<any>> {
		return axios.get(url, this.populateRequestConfig(config));
	}

	private post(url: string, config?: any): Promise<AxiosResponse<any>> {
		return axios.post(url, config.params, this.populateRequestConfig(config));
	}

	private patch(url: string, config?: any): Promise<AxiosResponse<any>> {
		return axios.patch(url, config.params, this.populateRequestConfig(config));
	}

	public setMethod(newMethod: HttpMethodType): this {
		this._method = newMethod;
		return this;
	}
};

export default ApiService;