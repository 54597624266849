import "./dashboard.component.scss";

import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Select, Button, notification } from "antd";
import { Footer } from "antd/es/layout/layout";
import { CUSTOMER_LIST, DATA_FETCHING_ERROR, TREND_OF_ACCURACY, OVERALL_ACCURACY, RESOURCE_CONTIBUTION_TREND } from "../../../constants/messages";
import { CustomerStatus } from "../../../enum/customer-status.enum";
import { ReportServices } from "../../../services/report.services";
import { OverallAccuracyType } from "../../../types/overall-accuracy.types";
import { ResourceContributionTypes } from "../../../types/resource-contribution-types";
import { TrendAccuracyTypes } from "../../../types/trendAccuracy.types";
import OverAllAccuracy from "../../../shared/components/chartsWidgets/overall-accuracy/overall-accuracy.component";
import ResourceContributionTrend from "../../../shared/components/chartsWidgets/resource-contribution-trend/resource-contribution-trend.component";
import TrendOfAccuracy from "../../../shared/components/chartsWidgets/trend-of-accuracy/trend-of-accuracy.component";

const Dashboard: React.FC = () => {
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [isSubmited, setIsSubmited] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState([]);
  const [accuracyTrendData, setAccuracyTrendData] = useState([] as TrendAccuracyTypes[]);
  const [resourceContributionData, setResourceContributionData] = useState([] as ResourceContributionTypes[]);
  const [overallAccuracyData, setOverallAccuracyData] = useState([] as OverallAccuracyType[]);
  const resportService = new ReportServices();

  useEffect(() => {
    getAllActiveCustomers();
    getResourceContributionTrend();
  }, []);

  const getAllActiveCustomers = () => {
    resportService.getAllCustomers()
      .then((response: any) => {
        const filteredActiveCustomers = response.filter((customer: any) => {
          return customer.status === CustomerStatus.ACTIVE
        });
        setActiveCustomer(filteredActiveCustomers);
      })
      .catch((responseError) => {
        notification['error']({
          message: CUSTOMER_LIST,
          description: DATA_FETCHING_ERROR,
        });
      })
  };

  const getAccuracyTrend = () => {
    resportService.getAccuracyTrend(selectCustomer)
      .then((response) => {
        setAccuracyTrendData(response)
      })
      .catch((responseError) => {
        notification['error']({
          message: TREND_OF_ACCURACY,
          description: DATA_FETCHING_ERROR,
        });
      })
  };

  const getOverallAccuracy = () => {
    resportService.getOverallAccuracy(selectCustomer)
      .then((response) => {
        setOverallAccuracyData(response);
      })
      .catch((responseError) => {
        notification['error']({
          message: OVERALL_ACCURACY,
          description: DATA_FETCHING_ERROR,
        });
      })
  };

  const getResourceContributionTrend = () => {
    resportService.getResourceContribution()
      .then((response) => {
        setResourceContributionData(response);
      })
      .catch((responseErrors) => {
        notification['error']({
          message: RESOURCE_CONTIBUTION_TREND,
          description: DATA_FETCHING_ERROR,
        });
      })
  };

  const renderSelectCustomer = () => {
    const handelSubmit = () => {
      getAccuracyTrend();
      getOverallAccuracy();
      setIsSubmited(true);
    };

    const handelCustomerselect = (value: any) => {
      setSelectCustomer(value);
      setIsSubmited(false)
    };

    return (
      <div className='row ms-5'>
        <div className='form-group col-12 col-lg-6 col-md-12 text-left p-0'>
          <label htmlFor="customerControl" className='customerLabel col-lg-2 col-md-3 col-sm-6 col-xs-12 p-0 ms-2'>
            Customer :
          </label>

          <Select
            id="customerControl"
            defaultValue={activeCustomer[0]?.name}
            style={{ width: 300 }}
            placeholder="Select Customer"
            className="col-lg-6 col-md-4 col-sm-6 col-xs-12 p-0 mt-3"
            onChange={handelCustomerselect}
            value={selectCustomer}
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {activeCustomer?.map((customer: any) => {
              return (
                <option value={customer?.id} key={customer?.id} >
                  {customer?.name}
                </option>
              );
            })}
          </Select>
          <Button className="col-xs-12 btn btn-primary ms-2" onClick={handelSubmit}>
            Submit
          </Button>
        </div>
      </div>
    )
  };

  return (
    <div className='dashboard scrollable-page-area'>
      {renderSelectCustomer()}
      <Row className="widget-row mt-2 ms-1">
        <div className="col-sm-12 col-xs-12 col-md-6 col-lg-3 p-0 me-1 ms-5 border rounded left-widget">
          <OverAllAccuracy
            datas={overallAccuracyData}
            title={OVERALL_ACCURACY}
          />
        </div>
        <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8 p-0 border rounded right-widget">
          <TrendOfAccuracy
            datas={accuracyTrendData}
            title={TREND_OF_ACCURACY}
          />
        </div>
        <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 p-0 ms-5 me-2 mt-1 border">
          <ResourceContributionTrend
            datas={resourceContributionData}
            title={RESOURCE_CONTIBUTION_TREND}
          />
        </div>
      </Row>
      <Footer />
    </div>
  )
}

export default Dashboard;