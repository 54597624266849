import { StatusCodes } from "http-status-codes";
import {  URL_CUSTOMER_GOTO_RAWID, URL_CUSTOMER_SERVEY, URL_CUSTOMER_SOURCE_STATUS, URL_GET_ALL_USERS, URL_GET_DISTINC_STATUS, URL_PREVIOUS_SERVEY_QUESTION, URL_UPDATE_TO_ALLOCATE } from "../constants/url";
import { HttpMethodType } from "../enum/http-method-type.enum";
import ApiService from "./api.service";

export class CustomerService {

    public getDistinctStatus = async() => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_GET_DISTINC_STATUS);

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

    public getCustomerStatusData = async (
        status:string
    ): Promise<any> => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_CUSTOMER_SOURCE_STATUS.replace('{status}', status));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

    public getCustomerServeyData = async (
        payload:any
        ): Promise<any> => {
        let result = {};
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Post;
        const response = await apiService.send(URL_CUSTOMER_SERVEY, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }else if(response && response.status === Number(StatusCodes.NO_CONTENT)){
            result = [];
        }
        return result;
    };

    public getPreviousServeyData = async (
        payload:any
        ): Promise<any> => {
        let result = {};
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Post;
        const response = await apiService.send(URL_PREVIOUS_SERVEY_QUESTION, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }else if(response && response.status === Number(StatusCodes.NO_CONTENT)){
            result = {};
        }
        return result;
    };

    public updateCustomerServeyData = async (
        payload:any
        ): Promise<any> => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Patch;
        const response = await apiService.send(URL_CUSTOMER_SERVEY, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }
        return result;
    };

    public getCustomerDataByRawID = async (
        rawID:string
    ): Promise<any> => {
        let result = {};
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_CUSTOMER_GOTO_RAWID.replace('{rawId}', rawID));
        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }
        return result;
    };

    public getAllUsers = async() => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_GET_ALL_USERS);

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

    public updateAllocate = async (
        payload:any
        ): Promise<any> => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Patch;
        const response = await apiService.send(URL_UPDATE_TO_ALLOCATE, apiService.request<any[]>(payload));

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data
        }
        return result;
    };
}