import ApiService from "./api.service";
import { StatusCodes } from "http-status-codes";
import { HttpMethodType } from "../enum/http-method-type.enum";
import { URL_CUSTOMER_LOGIN } from "../constants/url";

export class AccountServices {

    public userLogin = async (
        payload:any
        ): Promise<any> => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Post;
        const response = await apiService.send(URL_CUSTOMER_LOGIN, apiService.request<any[]>(payload));
        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };
}