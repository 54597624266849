export const OPMS_TATVAM: string = '/private';
export const DASHBOARD: string = '/private/dashboard';
export const PROCESS_STATUS: string = '/private/processStatus';
export const ROUTE_LOGIN: string = '/login'
export const CUSTOMER : string = '/private/customer';
export const RESOURCE_MANAGEMENT: string= '/private/resourceManagement';
export const SEARCH_RAW_DATA: string='/private/searchRawdata';
export const REVIEW_RECORD: string='/private/reviewRecord';
export const TATVAM_SYNC: string='/private/tatvamSync';
export const EDIT_CUSTOMER_DETAILS:string='/private/edit-customer-details';
export const REPROCESS: string = '/private/reprocess';