import { StatusCodes } from "http-status-codes";
import {  URL_RESOURCE_MANAGEMENT } from "../constants/url";
import { HttpMethodType } from "../enum/http-method-type.enum";
import ApiService from "./api.service";

export class ResourceMangementService {

    public getResourceWiseCount = async() => {
        let result: any[] = [];
        const apiService = new ApiService();
        apiService.method = HttpMethodType.Get;
        const response = await apiService.send(URL_RESOURCE_MANAGEMENT);

        if (response && response.status === Number(StatusCodes.OK)) {
            result = response?.data?.data
        }
        return result;
    };

}