import { Button, DatePicker, Form, Input, Modal, Select, Table, notification } from 'antd';
import './reprocess.component.scss';
import React, { useEffect, useState } from 'react';
import { ReprocessService } from '../../../services/reprocess.service';
import { REPROCESS_CUSTOMER, DATA_FETCHING_ERROR, NO_USER_WITH_CUST_ID } from '../../../constants/messages';
import { AntGridFilterType } from '../../../enum/antd-grid-filter-type.enum';
import antdFilter from '../../../shared/components/antd-table/antd-filter.function';
import { sorterFunction, sortingDirections } from '../../../shared/components/antd-table/sorter.function';
import moment from 'moment';
import { DateFormat } from '../../../enum/date-format.enum';

const Reprocess: React.FC<any> = () => {

    const [custID, setCustID] = useState("" as string);
    const [custExist, setCustExist] = useState(false as boolean);
    const [source, setSource] = useState("" as string);
    const [startDate, setStartDate] = useState("" as string);
    const [endDate, setEndDate] = useState("" as string);
    const [sortedInfo, setSortedInfo] = useState('' as any);
    const [filteredInfo, setFilteredInfo] = useState(null as any);
    const [sourceData, setSourceData] = useState([] as any);
    const [openShow, setOpenShow] = useState(false as boolean);
    const [gridData, setGridData] = useState([] as any);
    const [gridDataFetched, setGridDataFetched] = useState(false as boolean);
    const reprocessData = new ReprocessService();
    const [form] = Form.useForm();

    useEffect(() => {
        getCustomerSource();
    }, [custID]);

    const getCustomerSource = () => {
        if (custID && custID !== "") {
            reprocessData.getCustomerAllSource(custID)
                .then((response: any) => {
                    if (response.length > 0) {
                        setCustExist(true);
                        const filteredResponse = response.filter(item => item.comment_type.toLowerCase() === "survey responses");
                        const namesLowerCase = filteredResponse.map(item => item.name.toLowerCase());
                        const uniqueNamesSet = new Set(namesLowerCase);
                        const uniqueNamesArray = [...uniqueNamesSet];
                        uniqueNamesArray.sort((a,b)=>a.localeCompare(b));
                        uniqueNamesArray.unshift("all");
                        setSourceData(uniqueNamesArray);
                    }
                    else
                        setCustExist(false);
                })
                .catch((responseError) => {
                    setCustExist(false);
                    setSourceData([]);
                    Modal.warning({
                        title: "Alert",
                        content: NO_USER_WITH_CUST_ID,
                        onOk: () => { }
                    });
                })
        };
    };

    const getCustomerDatails = () => {
        const customerPayload = {
            "customer_id": custID,
            "source": source,
            "start_date": moment(new Date(startDate)).format(DateFormat.YYYY_MM_DD),
            "end_date": moment(new Date(endDate)).format(DateFormat.YYYY_MM_DD)
        }
        if (custID && custID !== "") {
            reprocessData.getCustomerData(customerPayload)
                .then((response: any) => {
                    if (response?.data?.length > 0) {
                        const data = [{
                            "customer_id": response?.data[0]["customer_id"],
                            "source_name": response?.data[0]["source_name"],
                            "name": response?.data[0]["name"],
                            "no_of_records": response?.data.reduce((total: any, record: any) => total + record.no_of_records, 0)
                        }]
                        setGridData(data);
                        setGridDataFetched(true);
                    } else {
                        setGridData([]);
                        setGridDataFetched(true);
                    }
                })
                .catch((responseError) => {
                    setGridData([]);
                    setGridDataFetched(true);
                    console.log(responseError);
                    notification['error']({
                        message: REPROCESS_CUSTOMER,
                        description: DATA_FETCHING_ERROR,
                    });
                })
        };
    };

    const reprocessDetails = () => {
        const customerPayload = {
            "customer_id": custID,
            "source": source,
            "start_date": moment(new Date(startDate)).format(DateFormat.YYYY_MM_DD),
            "end_date": moment(new Date(endDate)).format(DateFormat.YYYY_MM_DD)
        }
        reprocessData.reprocess(customerPayload)
            .then((response: any) => {
                if (!response.message) {
                    notification['error']({
                        message: REPROCESS_CUSTOMER,
                        description: DATA_FETCHING_ERROR,
                    });
                }
                else {
                    Modal.success({
                        content: response.message,
                        onOk: () => {
                            resetFields();
                        }
                    });
                }
            })
            .catch((responseError) => {
                notification['error']({
                    message: REPROCESS_CUSTOMER,
                    description: DATA_FETCHING_ERROR,
                });
            })
    };

    const columnDef: any[] = [
        {
            title: "Customer Id",
            dataIndex: "customer_id",
            key: "customer_id",
            className: 'text-center',
            filteredValue: filteredInfo?.customer_id || null,
            sortOrder: sortedInfo?.field === 'customer_id' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'customer_id', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("customer_id", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Customer Name",
            dataIndex: "name",
            key: "name",
            className: 'text-start',
            filteredValue: filteredInfo?.name || null,
            sortOrder: sortedInfo?.field === 'name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("name", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "Source",
            dataIndex: "source_name",
            key: "source_name",
            className: 'text-start',
            filteredValue: filteredInfo?.source_name || null,
            sortOrder: sortedInfo?.field === 'source_name' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: string, b: string) => sorterFunction(a, b, 'source_name', 'string'),
            sortDirections: sortingDirections,
            ...antdFilter("source_name", AntGridFilterType.SearchBox, gridData),
        },
        {
            title: "# of Records",
            dataIndex: "no_of_records",
            key: "no_of_records",
            className: 'text-center',
            filteredValue: filteredInfo?.no_of_records || null,
            sortOrder: sortedInfo?.field === 'no_of_records' && sortedInfo?.order,
            showSorterTooltip: true,
            sorter: (a: number, b: number) => sorterFunction(a, b, 'no_of_records', 'number'),
            sortDirections: sortingDirections,
            ...antdFilter("no_of_records", AntGridFilterType.SearchBox, gridData),
        }
    ];

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any,
    ) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const onChangeCustomerId = (e: any) => {
        if (e.target.value && e.target.value === "") {
            setCustID("");
        }
        setCustID(e.target.value);
    };

    const onChangeSource = (value: string) => {
        setSource(value);
    };

    const onChangeStartDate = (value: any) => {
        console.log(value);
        setStartDate(value);
    };

    const onChangeEndDate = (value: any) => {
        setEndDate(value);
    };

    const onClickShow = () => {
        getCustomerDatails();
        setOpenShow(true);
    };

    const disabledDate = (current: any) => {
        return current && current < startDate;
    };

    const onReprocessButtonClick = () => {
        reprocessDetails();
    };

    const resetFields = () => {
        setGridData([]);
        setSourceData([]);
        setOpenShow(false);
        setCustExist(false);
        setCustID("");
        setEndDate("");
        setStartDate("");
        setSource("");
        form.resetFields();
    };


    const renderTabel = () => {
        return <>
            <Table
                className='reprocess-data'
                columns={columnDef}
                dataSource={gridData}
                onChange={handleTableChange}
                loading={!(gridData?.length > 0 || gridDataFetched)}
                pagination={gridData?.length > 10}
            />
            {
                gridData?.length !== 0 &&
                <Button
                    className='float-end mt-2'
                    type='primary'
                    onClick={onReprocessButtonClick}
                >
                    Reprocess
                </Button>
            }
        </>
    }

    const renderReprocess = () => {
        return (
            <div className='reprocess'>
                <Form
                    layout={'horizontal'}
                    labelAlign="right"
                    form={form}
                >
                    <div className='row'>
                        <Form.Item
                            className='col-lg-3 col-md-3 col-sm-6 col-xs-12'
                            label="Customer ID"
                            name='customer_id'
                        >
                            <Input
                                placeholder="Enter Customer ID"
                                onBlur={onChangeCustomerId}
                            />
                        </Form.Item>
                        <Form.Item
                            className='col-lg-3 col-md-3 col-sm-6 col-xs-12'
                            label="Source"
                            name='source'
                        >
                            <Select
                                className=''
                                placeholder={"Select Source"}
                                listItemHeight={10}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={onChangeSource}
                                disabled={!(custID !== "" && custExist)}
                            >
                                {sourceData?.map((sources: any, index: any) => {
                                    return (
                                        <Select.Option value={sources} key={sources} >
                                            {sources}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className='col-lg-2 col-md-3 col-sm-6 col-xs-12'
                            label="Start Date"
                            name='start_date'
                        >
                            <DatePicker
                                disabled={!(custID !== "" && custExist)}
                                onChange={onChangeStartDate}
                                format={"DD-MMM-YYYY"}
                            />
                        </Form.Item>
                        <Form.Item
                            className='col-lg-2 col-md-3 col-sm-6 col-xs-12'
                            label="End Date"
                            name='end_date'
                        >
                            <DatePicker
                                disabled={startDate === ""}
                                onChange={onChangeEndDate}
                                format={"DD-MMM-YYYY"}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <div className='col-lg-2 col-md-3 col-sm-6 col-xs-12'>
                            <Button
                                className='ms-5'
                                type='primary'
                                disabled={endDate === "" || startDate === "" || source === "" || custID === ""}
                                onClick={onClickShow}
                            >
                                Show
                            </Button>
                        </div>
                    </div>
                </Form>
                {openShow && renderTabel()}
            </div>
        )
    };

    return (
        <div className='scrollable-page-area p-2'>
            {renderReprocess()}
        </div>
    )
}
export default Reprocess;